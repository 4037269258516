import { Box, Container, Heading, Image, Text, Link, Flex, Button } from "@chakra-ui/react";


const PediatricianMobile = () => {
  return (
    <Box bg="white" py={8} textAlign="center" overflowX="hidden">
    <Container maxW="container.md">
    <Box position="relative" display="flex" justifyContent="center" mb={6}>
      {/* Immagine principale centrata */}
      <Image 
        src="/images/homepediatrician.png" 
        alt="App Kiddocare" 
        objectFit="contain" 
        maxW="250px" 
        mx="auto" 
        display="block" 
        zIndex="2" 
      />

      {/* Immagine di sfondo centrata rispetto alla principale */}
      <Image
        src="/images/rectviola.png"
        alt="Sfondo"
        objectFit="cover"
        position="absolute"
        top="70%"
        left="90%"
        transform="translate(-80%, -60%)"
        maxW="10000px"
        opacity={0.8}
        zIndex="1"
      />
    </Box>

        {/* Testo */}
        <Box position="relative" zIndex="2">
        <Heading fontSize="4xl" fontWeight="bold" color="black" mb={8}>
          Io sono Kiddocare
        </Heading>
        <Text mb={5} fontSize="md" color="gray.600" maxW="300px" mx="auto">
        Kiddocare è l’app che ti aiuta a gestire i pazienti e
        l’amministrazione del tuo studio in modo smart, con pochi click.</Text>
       
        </Box>
        </Container>

       {/* Seconda sezione */}
       <Box bg="white" py={10} textAlign="center">
        <Container>
            {/* Titolo e descrizione */}
            <Heading 
             fontSize="4xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" p={4} borderRadius="md" mb={8}
            >
      Ti mostro come posso aiutarti ogni giorno
    </Heading>

    <Text fontSize="2xl" color="gray.700" fontWeight="bold" mb={1}>
      Questa è la mia pagina principale
    </Text>
    <Text fontSize="lg" color="gray.600" mb={4}>
      Da qui potrai accedere a tutte le mie funzionalità
    </Text>

    {/* Immagine centrale */}
    <Flex direction={{ base: "column", md: "row" }} align="center" justify="center" gap={8}>
    <Box textAlign="center" mb={-10}>
      <Image src="/images/home2pediatrician.png" alt="App Screen" maxW="300px"  />
    </Box>
    </Flex>
    {/* Sezione icone con frasi */}
    <Flex direction={{ base: "column", md: "row" }} align="center" gap={3} pt="10" pb="4">
      
        {/* Colonna sinistra */}
        <Box textAlign="left" maxW="300px">
            <Flex align="center" gap={3} mb={2}>
            <Image src="/images/icon3.png" alt="Salute" boxSize="40px" />
            <Text fontSize="md" color="gray.700">
                <strong>Gestire</strong> più facilmente i tuoi pazienti
            </Text>
            </Flex>

            <Flex align="center" gap={3} mb={2}>
            <Image src="/images/icon2.png" alt="Pediatra" boxSize="40px" />
            <Text fontSize="md" color="gray.700">
                <strong>Consultare</strong> lo storico dei pazienti
            </Text>
            </Flex>
        
            <Flex align="left" gap={3} mb={2}>
            <Image src="/images/icon1.png" alt="Prenotazioni" boxSize="40px" />
            <Text fontSize="md" color="gray.700">
                Calendarizzare <strong>Visite</strong> in studio e <strong>Videochiamate</strong>
            </Text>
            </Flex>

            <Flex align="left" gap={3} mb={2}>
            <Image src="/images/icon.png" alt="Certificati" boxSize="45px" />
            <Text fontSize="md" color="gray.700">
                Inviare direttamente <strong>certificati</strong> e <strong>prescrizioni</strong>
            </Text>
            </Flex>
        </Box>

        </Flex>
    </Container>
    </Box>


    <Box bg="white">
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" mb={8}>
      Conversazioni rapide con i genitori
    </Heading>

    {/* Immagine centrata */}
    <Flex direction="column" align="center" gap={6} pt={10} mb={10}>
      <Box position="relative" display="flex" justifyContent="center">
        {/* Immagine in sottoimpressione */}
        <Image
          src="/images/rectgiallo.png"
          alt="Sfondo"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          maxW="500px"
          opacity={0.6}
          zIndex="1"
        />
        {/* Immagine principale */}
        <Image
          src="/images/chatmobile.png"
          alt="Consulto"
          maxW="300px"
          zIndex="2"
        />
      </Box>
    </Flex>

    {/* Testo con rettangoli accanto */}
    <Box textAlign="left" maxW="400px" mx="auto">
      <Flex align="center" gap={3} mb={4}>
        {/* Rettangolo Giallo */}
        <Box bg="yellow.400" width="8px" height="40px" borderRadius="md"></Box>
        
        {/* Testo */}
        <Text fontSize="mg" color="gray.700">
          <strong>Possibilità di comunicare</strong> tramite chat dedicata, telefonata e videochiamate.
        </Text>
      </Flex>

      <Flex align="center" gap={3} mb={1}>
        {/* Rettangolo Azzurro */}
        <Box bg="#5c64a9" width="5px" height="30px" borderRadius="md"></Box>
        
        {/* Testo */}
        <Text fontSize="mg" color="gray.700">
          <strong>Riassunto</strong> dei sintomi del paziente.
        </Text>

         </Flex>

         <Flex align="center" gap={3} mb={4}>
        {/* Rettangolo Giallo */}
        <Box bg="yellow.400" width="5px" height="30px" borderRadius="md"></Box>
        
        {/* Testo */}
        <Text fontSize="mg" color="gray.700">
          <strong>Assistenza dall'AI</strong> per formulazione di risposte rapide.</Text>
      </Flex>

      
    </Box>
  </Container>
</Box>


{/* Quarta sezione */}

<Box bg="white" py={20} textAlign="center">
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPediatrician.700" mb={4}>
      Richieste automatiche rapide
    </Heading>

    {/* Testo */}
    <Text fontSize="lg" color="gray.700" maxW="800px" mx="auto" mb={8}>
      Invio di ricette, certificati con pochi <strong> click</strong>.  <br/>
      Prescrizioni disponibili a breve.  <br/>
      <strong>Archivizione automatica</strong> dello storico del paziente.
    </Text>

    {/* Immagini */}
    <Flex justify="center" flexWrap="wrap" gap={4}>
    <Image src="/images/pipeline.png" alt="Richiedi certificati" maxW="500px" />
      
    </Flex>
    </Container>
    </Box>


    <Box bg="white" pt={10}>
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" mb={20}>
     Calendario visite
    </Heading>

    {/* Immagine centrata sotto il titolo */}
    <Box position="relative" display="flex" justifyContent="center" mb={10}>
      {/* Immagine in sottoimpressione */}
      <Image
        src="/images/rectgiallo.png"
        alt="Sfondo"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        maxW="800px"
        opacity={0.6}
        zIndex="0"
      />
      {/* Immagine principale */}
      <Image
        src="/images/visite.png"
        alt="Prenotazione Visite"
        maxW="300px"
        zIndex="1"
      />
    </Box>

    {/* Layout con scritte e rettangolini sotto in riga */}
    <Flex direction={{ base: "column" }} justify="center" gap={8}>
      <Flex align="left" gap={3}>

        <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
        <Text fontSize="lg" color="gray.700" textAlign="left">
          <strong>Gestisci visite</strong>, convenzionate o private, direttamente in app.
        </Text>
      </Flex>

      <Flex align="left" gap={3}>
        <Box bg="#5c64a9" width="8px" height="50px" borderRadius="md"></Box>
        <Text fontSize="lg" color="gray.700" textAlign="left">
          Accetta o Rifiuta<strong> l'appuntamento</strong> in base alle disponibilità.
        </Text>
      </Flex>

      <Flex align="center" gap={3}>
        <Box bg="yellow.400" width="7px" height="50px" borderRadius="md"></Box>
        <Text fontSize="lg" color="gray.700" >
          <strong>Visite</strong> sia in ambulatorio che videochiamate.
        </Text>
      </Flex>

      <Flex align="center" gap={3}>
        {/* Rettangolo Verde */}
        <Box bg="#5c64a9" width="7px" height="40px" borderRadius="md"></Box>
        {/* Testo */}
        <Text fontSize="lg" color="gray.700" textAlign="left">
          <strong>Disdici</strong> direttamente in app con un click.
        </Text>
      </Flex>

    
        </Flex>
        </Container>
        </Box>
        <Box bg="white" py={20}>
  <Container maxW="container.lg">

    
    {/* Seconda Box: Pagamenti Sicuri e Privacy */}
    <Box textAlign="center" maxW="600px" mx="auto">
      {/* Titolo */}
      <Heading fontSize="2xl" fontWeight="bold" color="primaryPediatrician.700" mb={4}>
     Privacy completa e Fatturazione Automatica
      </Heading>

      {/* Immagine */}
      <Image src="/images/gruppo.png" alt="Pagamenti Sicuri" maxW="300px" mx="auto" mb={4} />

      {/* Testo */}
      <Text fontSize="lg" color="gray.700">
        Garantiamo pagamenti sicuri direttamente in app e la protezione completa della tua privacy.
       Fatturazioni Automatiche.
      </Text>
      
    </Box>

  </Container>
</Box>
<Box bg="white" py={20}>
  <Container maxW="container.lg">

    {/* Titolo principale */}
    <Heading fontSize="3xl" fontWeight="bold" color="black" textAlign="center" mb={2}>
      Semplifica la gestione del tuo studio
    </Heading>
    <Heading fontSize="2xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" mb={4}>
      Scrivici per entrare a far parte della community di pediatri Kiddocare
    </Heading>

  {/* Icone con link */}
  <Flex justify="center" gap={6} pt={6}>
      
      {/* Email */}
      <Link href="mailto:info@kiddocare.it">
        <Box 
          bg="yellow.400" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "yellow.500" }}
        >
          <Image src="/images/email.png" alt="Email" boxSize="60px" />
        </Box>
      </Link>

      {/* WhatsApp */}
      <Link href="https://wa.me/393762385724">
        <Box 
          bg="teal.500" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "teal.600" }}
        >
          <Image src="/images/whatsapp.png" alt="WhatsApp" boxSize="60px" />
        </Box>
      </Link>

    </Flex>

 
   
        </Container>
        </Box>
       

          </Box>
        );
      };
   

export default PediatricianMobile;
