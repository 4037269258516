import { Box, Container, Heading, Image, Text, Link, Flex, Button } from "@chakra-ui/react";
import {Socialbar} from "../../layouts/socialbar";

const ParentPageMobile = () => {
  return (
    <Box bg="white" py={8} textAlign="center" overflowX="hidden">
    <Container maxW="container.md">
    <Box position="relative" display="flex" justifyContent="center" mb={6}>
      {/* Immagine principale centrata */}
      <Image 
        src="/images/singup.png" 
        alt="App Kiddocare" 
        objectFit="contain" 
        maxW="250px" 
        mx="auto" 
        display="block" 
        zIndex="2" 
      />

      {/* Immagine di sfondo centrata rispetto alla principale */}
      <Image
        src="/images/rectverde.png"
        alt="Sfondo"
        objectFit="cover"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        maxW="10000px"
        opacity={0.6}
        zIndex="1"
      />
    </Box>

        {/* Testo */}
        <Box position="relative" zIndex="2">
        <Heading fontSize="4xl" fontWeight="bold" color="black" mb={8}>
          Io sono Kiddocare
        </Heading>
        <Text mb={5} fontSize="md" color="gray.600" maxW="300px" mx="auto">
          Kiddocare è l’app che ti aiuta a gestire online la salute dei tuoi figli, rimanendo sempre in contatto con il pediatra.
        </Text>
      
        </Box>
        </Container>

       {/* Seconda sezione */}
       <Box bg="white" py={10} textAlign="center">
        <Container>
            {/* Titolo e descrizione */}
            <Heading 
             fontSize="4xl" fontWeight="bold" color="primaryPatient.700" textAlign="center" p={4} borderRadius="md" mb={8}
            >
      Ti mostro come posso aiutarti ogni giorno
    </Heading>

    <Text fontSize="2xl" color="gray.700" fontWeight="bold" mb={1}>
      Questa è la mia pagina principale
    </Text>
    <Text fontSize="lg" color="gray.600" mb={-2}>
      Da qui potrai accedere a tutte le mie funzionalità
    </Text>

    {/* Immagine centrale */}
    <Flex direction={{ base: "column", md: "row" }} align="center" justify="center" gap={8}>
    <Box textAlign="center" mb={-14}>
      <Image src="/images/home2.png" alt="App Screen" maxW="450px"  />
    </Box>
    </Flex>
    {/* Sezione icone con frasi */}
    <Flex direction={{ base: "column", md: "row" }} align="center" gap={3} pt="6">
      
        {/* Colonna sinistra */}
        <Box textAlign="left" maxW="300px">
            <Flex align="center" gap={3} mb={2}>
            <Image src="/images/icon3.png" alt="Salute" boxSize="40px" />
            <Text fontSize="md" color="gray.700">
                <strong>Prendersi cura</strong> della salute dei tuoi figli
            </Text>
            </Flex>

            <Flex align="center" gap={3} mb={2}>
            <Image src="/images/icon2.png" alt="Pediatra" boxSize="40px" />
            <Text fontSize="md" color="gray.700">
                <strong>Consultare</strong> il Pediatra
            </Text>
            </Flex>
        
            <Flex align="left" gap={3} mb={2}>
            <Image src="/images/icon1.png" alt="Prenotazioni" boxSize="40px" />
            <Text fontSize="md" color="gray.700">
                Prenotare <strong>Visite</strong> in studio e <strong>Videochiamate</strong>
            </Text>
            </Flex>

            <Flex align="left" gap={3} mb={2}>
            <Image src="/images/icon.png" alt="Certificati" boxSize="45px" />
            <Text fontSize="md" color="gray.700">
                Richiedere <strong>certificati</strong> e <strong>prescrizioni</strong>
            </Text>
            </Flex>
        </Box>

        </Flex>
    </Container>
    </Box>


    <Box bg="white">
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPatient.700" textAlign="center" mb={8}>
      Conversazioni rapide con il pediatra
    </Heading>

    {/* Immagine centrata */}
    <Flex direction="column" align="center" gap={6} pt={10} mb={10}>
      <Box position="relative" display="flex" justifyContent="center">
        {/* Immagine in sottoimpressione */}
        <Image
          src="/images/rectgiallo.png"
          alt="Sfondo"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          maxW="500px"
          opacity={0.6}
          zIndex="1"
        />
        {/* Immagine principale */}
        <Image
          src="/images/chatmobile.png"
          alt="Consulto"
          maxW="300px"
          zIndex="2"
        />
      </Box>
    </Flex>

    {/* Testo con rettangoli accanto */}
    <Box textAlign="left" maxW="400px" mx="auto">
      <Flex align="center" gap={3} mb={4}>
        {/* Rettangolo Giallo */}
        <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
        
        {/* Testo */}
        <Text fontSize="mg" color="gray.700">
          <strong>Possibilità di comunicare</strong> tramite chat dedicata, telefonata e videochiamate.
        </Text>
      </Flex>

      <Flex align="center" gap={3}>
        {/* Rettangolo Azzurro */}
        <Box bg="teal.500" width="5px" height="40px" borderRadius="md"></Box>
        
        {/* Testo */}
        <Text fontSize="mg" color="gray.700">
          <strong>Meno spostamenti</strong> in ambulatorio.
        </Text>
      </Flex>
    </Box>
  </Container>
</Box>


{/* Quarta sezione */}

<Box bg="white" py={20} textAlign="center">
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPatient.700" mb={4}>
      Richieste automatiche rapide
    </Heading>

    {/* Testo */}
    <Text fontSize="lg" color="gray.700" maxW="800px" mx="auto" mb={8}>
      Ricette, certificati a <strong>portata di click</strong>.  <br/>
      Prescrizioni disponibili a breve.  <br/>
      Ricevi i documenti direttamente in app.
    </Text>

    {/* Immagini */}
    <Flex justify="center" flexWrap="wrap" gap={4}>
    <Image src="/images/pipeline.png" alt="Richiedi certificati" maxW="500px" />
      
    </Flex>
    </Container>
    </Box>


    <Box bg="white" pt={10}>
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPatient.700" textAlign="center" mb={20}>
      Prenotazione Visite
    </Heading>

    {/* Immagine centrata sotto il titolo */}
    <Box position="relative" display="flex" justifyContent="center" mb={10}>
      {/* Immagine in sottoimpressione */}
      <Image
        src="/images/rectgiallo.png"
        alt="Sfondo"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        maxW="800px"
        opacity={0.6}
        zIndex="0"
      />
      {/* Immagine principale */}
      <Image
        src="/images/visite.png"
        alt="Prenotazione Visite"
        maxW="300px"
        zIndex="1"
      />
    </Box>

    {/* Layout con scritte e rettangolini sotto in riga */}
    <Flex direction={{ base: "column" }} justify="center" gap={8}>
      <Flex align="left" gap={3}>

        <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
        <Text fontSize="lg" color="gray.700" textAlign="left">
          <strong>Prenota visite</strong>, convenzionate o private, direttamente in app.
        </Text>
      </Flex>

      <Flex align="left" gap={3}>
        <Box bg="teal.500" width="8px" height="50px" borderRadius="md"></Box>
        <Text fontSize="lg" color="gray.700" textAlign="left">
          Scegli tu <strong>quando vuoi</strong> in base alle disponibilità.
        </Text>
      </Flex>

      <Flex align="center" gap={3}>
        <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
        <Text fontSize="lg" color="gray.700" >
          <strong>Visite</strong> in ambulatorio.
        </Text>
      </Flex>

      <Flex align="center" gap={3}>
        {/* Rettangolo Verde */}
        <Box bg="teal.500" width="8px" height="50px" borderRadius="md"></Box>
        {/* Testo */}
        <Text fontSize="lg" color="gray.700" textAlign="left">
          <strong>Videochiamate</strong> direttamente in app, facili e sicure.
        </Text>
      </Flex>

      <Flex align="center" gap={3}>
        {/* Rettangolo Giallo */}
        <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
        {/* Testo */}
        <Text fontSize="lg" color="gray.700">
          <strong>Disdici</strong> con un click.
        </Text>
        </Flex>
        </Flex>
        </Container>
        </Box>
        <Box bg="white" py={20}>
  <Container maxW="container.lg">

    {/* Prima Box: SOS ed Emergenze */}
    <Box textAlign="center" maxW="600px" mx="auto" mb={16}>
      {/* Titolo */}
      <Heading fontSize="4xl" fontWeight="bold" color="primaryPatient.700" mb={4}>
        SOS ed Emergenze
      </Heading>

      {/* Testo */}
      <Text fontSize="lg" color="gray.700" mb={4}>
        Se il pediatra lo abilita, potrai richiedere un consulto urgente per gestire le emergenze.
      </Text>

      {/* Immagine */}
      <Image 
        src="/images/sos.png" 
        alt="SOS ed Emergenze" 
        maxW="250px" 
        mx="auto" 
        mb={5}
      />

      {/* Disclaimer */}
      <Text fontSize="sm" color="gray.600" textAlign="center">
        <em>
          * Il servizio SOS di Kiddocare facilita ai genitori la segnalazione di problemi urgenti dei figli per un consulto pediatrico.
          Non sostituisce i servizi di emergenza/urgenza del Servizio Sanitario Nazionale (es. 118, guardia medica).
        </em>
      </Text>
    </Box>

    {/* Seconda Box: Pagamenti Sicuri e Privacy */}
    <Box textAlign="center" maxW="600px" mx="auto">
      {/* Titolo */}
      <Heading fontSize="2xl" fontWeight="bold" color="primaryPatient.700" mb={4}>
        Pagamenti sicuri e veloci <br /> e Privacy completa
      </Heading>

      {/* Immagine */}
      <Image src="/images/gruppo.png" alt="Pagamenti Sicuri" maxW="300px" mx="auto" mb={4} />

      {/* Testo */}
      <Text fontSize="lg" color="gray.700">
        Garantiamo pagamenti sicuri direttamente in app e la protezione completa della tua privacy.
      </Text>
      <Text fontSize="lg" color="gray.700" mt={2}>
        Nessun altro, se non tu e il tuo pediatra, avrà accesso ai tuoi dati e alle tue foto.
      </Text>
    </Box>

  </Container>
</Box>
<Box bg="white" py={20}>
  <Container maxW="container.lg">

    {/* Titolo principale */}
    <Heading fontSize="3xl" fontWeight="bold" color="black" textAlign="center" mb={2}>
      Semplifica la gestione della salute di tuo figlio
    </Heading>
    <Heading fontSize="2xl" fontWeight="bold" color="primaryPatient.600" textAlign="center" mb={4}>
    Chiedi al tuo pediatra se fa parte della community Kiddocare o invitalo a scriverci a <strong>info@kiddocare.it</strong>
   
   </Heading>



        </Container>
        </Box>
        
          <Box mt={6}>
            <Socialbar />
            </Box>

          </Box>
        );
      };
   

export default ParentPageMobile;
