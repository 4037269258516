import { Box, Container, Image, Link, SimpleGrid, Stack, Text, Flex } from "@chakra-ui/react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

export const Footer: React.FC = () => {
  return (
    <Box bg="gray.700" color="whiteAlpha.900" py={12} width="100%">
      <Container maxW="100%" px={{ base: 6, md: 16 }}>
        
        {/* Logo in una riga separata */}
        <Flex justify={{ base: "center", md: "flex-start" }} mb={12}>
          <Image src="/images/Logo_Kiddocare.png" alt="Kiddocare" height="40px" />
        </Flex>

        {/* Sezione a tre colonne che occupa tutta la larghezza */}
        <SimpleGrid 
          columns={{ base: 1, md: 3 }} 
       
          width="100%"
        >
          
          {/* Colonna Sinistra: Descrizione */}
          <Stack align="flex-start" textAlign="left" width="100%">
            <Text fontSize="sm" mb={{ base: 4, md: 2 }}>
              Prenota visite pediatriche online e <br/> 
              consulta il tuo pediatra direttamente dal tuo smartphone.
            </Text>
            <Text fontSize="sm">
              <strong>AIHEALTH S.r.l.</strong><br />
              Via Santa Sofia 24, 20122 Milano (MI)<br />
              P.IVA 13640270966<br />
              <Link href="mailto:aihealth@pec.it" color="whiteAlpha.900" _hover={{ textDecoration: "underline" }}>
                aihealth@pec.it
              </Link>
            </Text>
          </Stack>

          {/* Colonna Centrale: Link di navigazione */}
          <Stack align="flex-start" textAlign="left" width="100%" mt={{ base: 0, md: 2 }}>
            <Box mb={{ base: 4, md: 2 }}>
              <Link href="https://ai-health.it/" color="whiteAlpha.900" _hover={{ textDecoration: "underline" }}>
                Cosa facciamo
              </Link>
            </Box>
            <Box>
              <Link href="../../policies/parents/policy-privacy" color="whiteAlpha.900" _hover={{ textDecoration: "underline" }}>
                Termini e Condizioni & Privacy e Policy
              </Link>
            </Box>
          </Stack>

          {/* Colonna Destra: Social & Contatti */}
          <Stack align="flex-start" textAlign="left" width="100%" mt={{ base: 0, md: 2 }}>
            <Text fontSize="md" fontWeight="bold" mb={{ base: 4, md: 2 }}>Seguici su</Text>
            <Flex gap={6} mb={{ base: 6, md: 4 }}>
              <Link href="https://www.linkedin.com/company/aihealth-s-r-l">
                <FaLinkedin size={24} color="white" />
              </Link>
              <Link href="https://www.instagram.com/kiddocare.it/">
                <FaInstagram size={24} color="white" />
              </Link>
            </Flex>

            <Text fontSize="md" fontWeight="bold" mb={{ base: 4, md: 2 }}>Contattaci</Text>
            <Box mb={{ base: 6, md: 4 }}>
              <Link href="mailto:info@kiddocare.it" color="whiteAlpha.900" _hover={{ textDecoration: "underline" }}>
                info@kiddocare.it
              </Link>
            </Box>

            <Text fontSize="md" fontWeight="bold" mb={{ base: 4, md: 2 }}>Supporto clienti</Text>
            <Box mb={{ base: 6, md: 4 }}>
              <Link href="mailto:care@kiddocare.it" color="whiteAlpha.900" _hover={{ textDecoration: "underline" }}>
                care@kiddocare.it
              </Link>
            </Box>

            <Text fontSize="md" fontWeight="bold" mb={{ base: 4, md: 2 }}>Supporto pediatri</Text>
            <Box>
              <Link href="mailto:support@kiddocare.it" color="whiteAlpha.900" _hover={{ textDecoration: "underline" }}>
                support@kiddocare.it
              </Link>
            </Box>
          </Stack>
        </SimpleGrid>

        {/* Copyright */}
        <Text textAlign="center" fontSize="sm" pt={12}>
          © {new Date().getFullYear()} Kiddocare. Tutti i diritti riservati
        </Text>
      </Container>
    </Box>
  );
};
