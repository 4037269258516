import { useBreakpointValue } from "@chakra-ui/react";
import PediatricianMobile from "./pediatrician.mobile";
import { Box, Container, Flex, Heading, Image, Text, Link, Em, Button } from "@chakra-ui/react";


const PediatricianPage = () => {
  
  // Se lo schermo è md o più piccolo, usa la versione mobile
  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false });

  if (isSmallScreen) {
    return <PediatricianMobile />; // Mostra il file mobile
  }

  return (
    <Box bg="white" py={6} position="relative" >
      <Container maxW="container.xl">
        <Flex direction="row" align="center" justify="right" gap={4} position="relative">
          {/* Box con testo e pulsante */}
          <Box textAlign="left" mt={-20} position="relative" zIndex="2">
            <Heading fontSize="7xl" fontWeight="bold" color="black" mb={8}>
              Io sono
            </Heading>
            <Text mb={4} fontSize="md" color="gray.600" maxW="300px">
              Kiddocare è l’app che ti aiuta a gestire i pazienti e
              l’amministrazione del tuo studio in modo smart, con pochi click. </Text>
            
            </Box>

          {/* Immagine principale */}
          <Box position="relative">
            <Image src="/images/homepediatrician.png" alt="App Kiddocare" objectFit="contain" maxW="300px" position="relative" zIndex="2" />
            <Image
              src="/images/rectviola.png"
              alt="Sfondo"
              objectFit="cover"
              position="absolute"
              top="-50%"
              right="-260%"
              maxW="6000px"
              opacity={0.6}
              zIndex="1"
            />
          </Box>

          {/* Box con testo e immagine lampadina */}
          <Box textAlign="left" ml={{ md: -10, lg: 5 }} mt={{ md: 0, lg: 14 }} position="relative" zIndex="2">
            <Heading fontSize="7xl" fontWeight="bold" color="black" mb={8}>
              Kiddocare
            </Heading>
            <Image src="/images/lampadina.png" alt="Decorazione" objectFit="contain" maxW="500px" />
          </Box>
        </Flex>
      </Container>


      <Box bg="white" pt={20} pb={1} textAlign="center">
  <Container>
    {/* Titolo e descrizione */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" p={4} borderRadius="md" mb={8}>
      Ti mostro come posso aiutarti ogni giorno
    </Heading>

    <Text fontSize="2xl" color="gray.700" fontWeight="bold" mb={1}>
      Questa è la mia pagina principale
    </Text>
    <Text fontSize="lg" color="gray.600" mb={6}>
      Da qui potrai accedere a tutte le mie funzionalità
    </Text>

    {/* Layout 3 colonne con immagine centrale */}
    <Flex direction={{ base: "column", md: "row" }} align="center" justify="center" gap={8}>
      
      {/* Colonna sinistra */}
      <Box textAlign="right" maxW="400px" flex="1">
        <Flex direction="row-reverse" align="center" gap={3} mb={4}>
          <Image src="/images/icon3.png" alt="Salute" boxSize="40px" />
          <Text fontSize="md" color="gray.700">
            <strong>Gestire</strong> più facilmente <br />  i tuoi pazienti
          </Text>
        </Flex>

        <Flex direction="row-reverse" align="center" gap={3} mb={4}>
          <Image src="/images/icon2.png" alt="Pediatra" boxSize="40px" />
          <Text fontSize="md" color="gray.700">
            <strong>Consultare</strong> lo storico dei pazienti
          </Text>
        </Flex>
      </Box>

      {/* Immagine centrale */}
      <Box flex="1" display="flex" justifyContent="center">
        <Image src="/images/home2pediatrician.png" alt="App Screen" maxW="350px" />
      </Box>

      {/* Colonna destra */}
      <Box textAlign="left" maxW="400px" flex="1">
        <Flex direction="row" align="center" gap={3} mb={4}>
          <Image src="/images/icon1.png" alt="Prenotazioni" boxSize="40px" />
          <Text fontSize="md" color="gray.700">
            Calendarizzare <strong>Visite</strong> in studio e <br /> <strong>Videochiamate</strong>
          </Text>
        </Flex>

        <Flex direction="row" align="center" gap={3}>
          <Image src="/images/icon.png" alt="Certificati" boxSize="45px" />
          <Text fontSize="md" color="gray.700">
            Inviare direttamente <strong>certificati</strong> e <strong>prescrizioni</strong>
                </Text>
                </Flex>
            </Box>
            
            </Flex>
        </Container>
        </Box>


   {/* Terza sezione */}

    <Box bg="white" >
    <Container maxW="container.lg" pt={10}>
        {/* Titolo */}
        <Heading fontSize="4xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" mb={8}>
        Conversazioni rapide con i genitori
        </Heading>

    {/* Contenuto con immagine a sinistra e testo a destra */}
    <Flex align="center" justify="space-between" gap={10} pt={10} mb={20}>

      {/* Box Immagine */}
      <Box position="relative" flex="1" display="flex" justifyContent="center">
        {/* Immagine in sottoimpressione */}
        <Image
          src="/images/rectgiallo.png"
          alt="Sfondo"
          position="absolute"
          top="100%"
          left="100%"
          transform="translate(-80%, -60%)"
          maxW="900px"
          opacity={0.6}
          zIndex="1"
        />
        {/* Immagine principale */}
        <Image
          src="/images/chat1.png"
          alt="Consulto"
          maxW="550px"
          zIndex="1"
        />
      </Box>

      {/* Box Testo con rettangoli colorati */}
        <Box flex="1" textAlign="left" maxW="400px">
        <Flex align="center" gap={3} mb={4}>
            {/* Rettangolo Giallo */}
            <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
            
            {/* Testo */}
            <Text fontSize="lg" color="gray.700">
            <strong>Possibilità di comunicare</strong> tramite chat dedicata, telefonata e videochiamate.
            </Text>
        </Flex>

        <Flex align="center" gap={3}  mb={2}>
            {/* Rettangolo Azzurro */}
            <Box bg="#5c64a9" width="5px" height="40px" borderRadius="md"></Box>
            
            {/* Testo */}
            <Text fontSize="lg" color="gray.700">
            <strong>Riassunto</strong> dei sintomi del paziente.  </Text>
        </Flex>
        <Flex align="center" gap={3} mb={4}>
            {/* Rettangolo Giallo */}
            <Box bg="yellow.400" width="6px" height="40px" borderRadius="md"></Box>
            
            {/* Testo */}
            <Text fontSize="lg" color="gray.700">
            <strong>Assistenza dall'AI</strong> per formulazione di risposte rapide.</Text>
        </Flex>
        </Box>
        


    </Flex>
  </Container>
</Box>


<Box bg="white" pt={20} pb={20} textAlign="center">
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="3xl" fontWeight="bold" color="primaryPediatrician.700" mb={4}>
      Richieste automatiche rapide
    </Heading>

    {/* Testo */}
    <Text fontSize="lg" color="gray.700" maxW="800px" mx="auto" mb={8}>
      Invio di ricette, certificati con pochi <strong> click</strong>.  <br/>
      Prescrizioni disponibili a breve. <br/>
      <strong>Archiviazione automatica</strong> nello storico del paziente.
    </Text>

    {/* Immagini */}
    <Flex justify="center" flexWrap="wrap" gap={4}>
      <Image src="/images/pipeline.png" alt="Richiedi certificati" maxW="900px" />
      
    </Flex>
    </Container>
    </Box>
    
    <Box bg="white" pt={10}>
  <Container maxW="container.lg">


    {/* quinta sezione */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" mb={20}>
      Calendario Visite
    </Heading>

    {/* Layout con lista allineata a destra e immagine a destra */}
    <Flex align="center" justify="space-between" >
      
      {/* Lista allineata a destra con rettangoli a sinistra */}
      <Box textAlign="right" maxW="700px" >
        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Giallo */}
          <Text fontSize="lg" color="gray.700">
            <strong>Gestisci visite</strong>, convenzionate o private, direttamente in app.
          </Text>
          <Box bg="yellow.400" width="10px" height="70px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
          
        </Flex>

        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Verde */}
           <Text fontSize="lg" color="gray.700">
            Accetta o Riufiuta <strong> l'appuntamento</strong> in base alle tue disponibilità.
          </Text>
          <Box bg="#5c64a9" width="8px" height="50px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
         
        </Flex>

        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Giallo */}
          <Text fontSize="lg" color="gray.700">
           Visite sia in <strong> ambulatorio</strong> che in  <strong> videochiamata</strong>.
          </Text>
          <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
          
        </Flex>

        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Verde */}
          <Text fontSize="lg" color="gray.700">
            <strong>Disdici</strong> direttamente in app con un click.
          </Text>
          <Box bg="#5c64a9" width="8px" height="50px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
          
        </Flex>

       
      </Box>


      <Box>
        {/* Immagine in sottoimpressione */}
        <Image
          src="/images/rectgiallo.png"
          alt="Sfondo"
          position="absolute"
          top="100%"
          left="100%"
          transform="translate(-90%, -80%)"
          maxW="1000px"
          opacity={0.6}
          zIndex="0"
        />
        {/* Immagine principale */}
        <Image
           src="/images/visite.png" 
           alt="Prenotazione Visite" 
           maxW="500px" 
           ml={-20} // Sposta a sinistra
           mr="auto" // Spinge verso sinistra
           position="relative" 
           left="-180px" // Ulteriore spostamento a sinistra
         
        />
      </Box>

    </Flex>
  </Container>
</Box>

{/* sesta sezione */}
<Box bg="white" py={20}>
  <Container maxW="container.lg">
    
    
    <Flex align="flex-start" justify="center" gap={20}>
      
    
      {/* Colonna Destra: Pagamenti sicuri e Privacy completa */}
      <Box textAlign="center" maxW="900px">
        {/* Titolo */}
        <Heading fontSize="3xl" fontWeight="bold" color="primaryPediatrician.700" mb={10}>
         Privacy completa e Fatturazione automantica
        </Heading>
        
        {/* Icone */}
        <Flex justify="center" gap={5} mb={3}>
          <Image src="/images/gruppo.png" alt="Pagamenti Sicuri" maxW="300px" />
         
        </Flex>

        {/* Testo */}
        <Text fontSize="lg" color="gray.700">
          Garantiamo pagamenti sicuri direttamente in app <br/>e la protezione completa della tua privacy.
          <br/>
          Fatturazioni automatiche.
        </Text>
      </Box>

    </Flex>
    

  </Container>
</Box>
<Box bg="white" py={20} textAlign="center">
  <Container maxW="container.lg">


      <Heading fontSize="3xl" fontWeight="bold" color="black" textAlign="center" mb={2}>
      Semplifica la gestione del tuo studio
    </Heading>
    <Heading fontSize="2xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" mb={4}>
      Scrivici per entrare a far parte della community di pediatri Kiddocare
    </Heading>

  {/* Icone con link */}
  <Flex justify="center" gap={6} pt={6}>
      
      {/* Email */}
      <Link href="mailto:info@kiddocare.it">
        <Box 
          bg="yellow.400" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "yellow.500" }}
        >
          <Image src="/images/email.png" alt="Email" boxSize="60px" />
        </Box>
      </Link>

      {/* WhatsApp */}
      <Link href="https://wa.me/393762385724">
        <Box 
          bg="teal.500" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "teal.600" }}
        >
          <Image src="/images/whatsapp.png" alt="WhatsApp" boxSize="60px" />
        </Box>
      </Link>

    </Flex>

    


   
  </Container>
</Box>



    </Box>
    
  );
};

export default PediatricianPage;
