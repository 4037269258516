import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/ui/button';
import { Carousel, CarouselImage } from '../../components/ui/carousel';

import { Box, Flex, Heading, Text, Badge, Container, Input, Image, Grid, List, ListItem, Icon, VStack, Textarea } from "@chakra-ui/react";


export const Home: React.FC = () => {

  return (
    <Box position="relative" zIndex={0} width="100%"  overflow="hidden"> 
  {/* Box senza padding, immagine grande che scappa sotto la scritta */}
  <Flex
  direction={{ base: "column", lg: "row" }}
  align={{ base: "center" }}
  justify="space-between"
  zIndex={2}
  position="relative"
  pb={{ base: "3rem", md: "5rem",lg: "16rem" }}
  pt={{ base: "3rem", md: "5rem", lg: "8rem" }}
  px={{ base: "4rem"}}
>
  <Box
    flex="1"
    textAlign={{ base: "left", md: "left" }}
    alignSelf={{ base: "center", md: "center" }}
    maxW={{ md: "600px" }}
    ml={{ base: "3vw", md: "1vw", lg: "18vw" }}
    
    position="relative"
    zIndex={2}
  >
    <Heading
  color="primaryPatient.500"
  as="h2"
  fontSize={{ base: "4xl", sm: "4xl", md: "5xl", lg: "5xl" }}
  mb="3rem"
  lineHeight="1.1"  // Migliora la leggibilità
  fontWeight="bold"
  textAlign="left"
  maxW="50ch" // Imposta una larghezza massima per mantenere sempre 2 righe
  whiteSpace="normal"
>
  La salute dei bambini è diventata più semplice
</Heading>

    <Heading
      color="primaryPatient.500"
      as="h2"
      fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}
      lineHeight={{ base: "1", sm: "1", md: "1" }}
      mb="2rem"

    >
      Benvenuti su Kiddocare
    </Heading>

    <Text mb="3rem" fontSize="lg" maxW="600px">
      Pensato per il settore pediatrico, Kiddocare utilizza l’intelligenza
      artificiale per migliorare l'interazione tra medico e paziente, offrendo
      soluzioni personalizzate in grado di soddisfare le esigenze di entrambi.
    </Text>

    <Box 
  display="flex"
  gap= {{ base: "10px", md: "50px" }} 
  flexDirection={{ base: "column", md: "row" }} // Mobile: verticale, Desktop: orizzontale
  alignItems="center"
  justifyContent={{ base: "center", md: "center" }} // Centra in mobile, allinea a sinistra su desktop
  width="90%"
>
  <Button
    bg="primaryPatient.500"
    color="white"
    borderRadius="full"
    zIndex="1000"
    _hover={{ bg: "primaryPatient.700" }}
    fontSize="xl"
    p={{ base:  "1.5rem 3.0rem ", md: "1.5rem 3.0rem" }}
    asChild
    width={{ base: "90%", md: "auto" }} // In mobile prende tutta la larghezza
    mb={{ base: "2rem", md: "0" }} // Aggiunge spazio tra i bottoni solo su mobile
  >
    <Link to={"parentpage"}> Sei un genitore? </Link>
  </Button>
  <Button
    bg="#7680d9" /* Colore di sfondo viola */
    color="white" /* Testo bianco */
    borderRadius="full"
    zIndex="1000"
    fontSize="xl"
    p="1.5rem 3.0rem"
    _hover={{ bg: "#5c64a9" }} /* Cambia colore al passaggio del mouse */
    asChild
    width={{ base: "90%", md: "auto" }} 
    mb={{ base: "2rem", md: "0" }}// In mobile prende tutta la larghezza
  >
    <Link to={"pediatrician"}> Sei un pediatra? </Link>
  </Button>
</Box>


  </Box>

  {/* Contenitore immagini */}
  <Box
    position={{ base: "absolute" , lg: "absolute" }}
    top={{ base: "0", lg: "0" }}
    left={{ base: "-80", lg: "0" }}
    width={{ base: "195%", lg: "100%" }}
    height={{ base: "100%", lg: "100%" }}
    zIndex={-1}
    overflow="hidden"
    display={{ base: "block", md: "block", lg: "block" }}
  >
    {/* Immagine principale come sfondo */}
    <Image
      src="/images/royaltyfree/benvenuti.png"
      alt="Sfondo benvenuti"
      position={{ md: "absolute", lg: "absolute" }}
      width="100%"
      height="100%"
      objectFit="cover" 
      zIndex={-1}
      opacity={{ base:0.3, md: 0.5, lg: 0.7 }}
    />

    {/* Seconda immagine ancora più in sottoimpressione */}
    <Image
      src="/images/rectgiallo.png"
      alt="Decorazione secondaria"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      objectFit="cover"
      zIndex={-2}
      opacity={{ base:0.2, md: 0.3, lg: 0.3 }}
    />
  </Box>
</Flex>

{/* Seconda Sezione: Galleria di immagini */}
<Flex 
  justify="space-around" 
  p="2rem" 
  wrap="wrap" 
  gap={{ base: "3rem", md: "0rem" }} /* Maggiore distanza tra i blocchi in mobile */
  bg="primaryPatient.200" 
  pb={{ base: "4rem", md: "5rem", lg: "7rem" }}
  pt={{ base: "5rem", md: "5rem", lg: "7rem" }}
  px={{ base: "1rem" }}
  direction={{ base: "column", md: "row" }}
>

  <Box textAlign="center" w={{ base: "100%", md: "30%" }} mb={{ base: "1rem", md: "0" }}>
    <Image
      src='/images/royaltyfree/vision.png'
      alt="Gallery 1"
      w="100%"
    />
    <Text fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }} fontWeight="bold" color="primaryPatient.700" mt={{ base: "0rem", md: "2rem" }} mb="1rem">
      La nostra Vision
    </Text>
    <Text fontSize="lg" maxW="85%" mx="auto">
      Consentire ai pediatri di ottimizzare il loro tempo e offrire ai genitori strumenti semplici per monitorare la salute dei loro figli, grazie a soluzioni tecnologiche avanzate.
    </Text>
  </Box>

  <Box textAlign="center" w={{ base: "100%", md: "30%" }} mb={{ base: "1rem", md: "0" }}>
    <Image
      src='/images/royaltyfree/mission.png'
      alt="Gallery 2"
      w="100%"
    />
    <Text fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }} fontWeight="bold" color="primaryPatient.700"  mt={{ base: "0rem", md: "2rem" }} mb="1rem">
      La nostra Mission
    </Text>
    <Text fontSize="lg" maxW="85%" mx="auto">
      Innovare il settore pediatrico, semplificando la relazione tra medico e paziente grazie alle nuove tecnologie, per rendere l'assistenza sanitaria più fluida ed efficace.
    </Text>
  </Box>

  <Box textAlign="center" w={{ base: "100%", md: "30%" }} mb={{ base: "1rem", md: "0" }}>
    <Image
      src='/images/family.png'
      alt="Gallery 3"
      w="100%"
    />
    <Text fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }} fontWeight="bold"  color="primaryPatient.700" mt={{ base: "0rem", md: "2rem" }} mb="1rem">
      Il nostro background
    </Text>
    <Text fontSize="lg" maxW="85%" mx="auto">
      Nata dall'esigenza di un pediatra, con decenni di esperienza, di velocizzare la comunicazione attraverso un canale online dedicato.
    </Text>
  </Box>

</Flex>



  <Box as="section" bg="gray.50" p="4rem">
  <Flex
    direction={{ base: "column", md: "row" , lg: "row"}}
    align="stretch" /* Allinea le colonne per altezza */
    justify="space-between" /* Spaziatura uniforme tra le colonne */
    gap="1rem" /* Aggiunge spazio tra le colonne */
      >
    {/* Prima sezione con immagini di sfondo */}
    <Box
      flex="1"
      textAlign="center"
      p={{base:"0rem",md:"2rem"}}
      position="relative"
    >
      <Heading size= {{base:"4xl",md:"5xl"}} fontWeight="bold" color="primaryPatient.700" mb="5rem" >
    Come funziona
  </Heading>
      {/* Immagine di sfondo dietro */}
      <Image
        src="/images/background1.png"
        alt="Immagine decorativa di sfondo"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        opacity={0.3}
        zIndex={-1}
      />
      <Image
        src="/images/background2.png"
        alt="Seconda immagine di sfondo"
        position="absolute"
        bottom="0"
        right="0"
        width="100%"
        height="100%"
        objectFit="cover"
        opacity={0.3}
        zIndex={-2}
      />
    </Box>
    </Flex>
</Box>



{/* Sezione Come Funziona */}
<Box   id="come-funziona"
  style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '50px', 
    textAlign: 'center', 
    marginTop: '-90px', 
    marginBottom: '100px' 
    
  }}
>


  <Box 
    style={{ 
      display: 'flex', 
      flexDirection: 'row', 
      gap: '15px', /* Regola la distanza tra le colonne */
      justifyContent: 'center', 
      flexWrap: 'wrap', 
      alignItems: 'center',
      maxWidth: "90%", 
      margin: "0 auto" 
    }}
  >

    <Box style={{ flex: '1', minWidth: '550px', textAlign: 'center', position: "relative" }}>
      <img 
        src="/images/genitore.png" 
        alt="Descrizione immagine 2" 
        style={{ width: '56%', borderRadius: '8px', display: 'block', margin: '0 auto' }} 
      />
      
      {/* Immagine dietro */}
      <Image
        src="/images/rectverde.png"
        alt="Sfondo pediatra"
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        width="400%"
        height="auto"
        objectFit="cover"
        opacity={0.7}
        zIndex={-1}
      />
      
      <Box 
  flex="1"
  minWidth="250px"
  maxWidth={{ base: "300px", md: "500px" }} 
  margin="0 auto"
  textAlign="center"
>

  {/* Titolo con Text */}
  <Text fontSize="xl" fontWeight="bold" mt="1rem">
    La salute di tuo figlio a portata di mano
  </Text>

  {/* Paragrafo */}
  <Text fontSize="lg">
    
    Prenota visite, richiedi certificati e comunica <br/>
    direttamente con il pediatra in pochi click.
  </Text>
</Box>


      {/* Bottone sotto il testo del pediatra */}
      <Box mt="3rem" pb={{ base: "7rem", md: "1.5rem", lg: "1.5rem" }}>
        <Button
          bg="primaryPatient.600"
          color="white"
          borderRadius="full"
          p="1.7rem 4rem"
          fontSize="xl"
          _hover={{ bg: "primaryPatient.700" }}
        >
          <Link to={'parentpage'}> Scopri di più</Link>
        </Button>
      </Box>
    </Box>

    <Box style={{ flex: '1', minWidth: '550px', textAlign: 'center', position: "relative" }}>
      <img 
        src="/images/pediatrician.png" 
        alt="Descrizione immagine 1" 
        style={{ width: '61%', borderRadius: '8px', display: 'block', margin: '0 auto' }} 
      />
      
      {/* Immagine dietro */}
      <Image
        src="/images/rectviola.png"
        alt="Sfondo genitore"
        position="absolute"
        bottom="-10px"
        left="50%"
        transform="translateX(-80%)"
        width="400%"
        height="100%"
        objectFit="cover"
        opacity={0.6}
        zIndex={-1}
      />

<Box 
  flex="1"
  minWidth="250px"
  maxWidth={{ base: "300px", md: "500px" }} // ✅ CORRETTO
  margin="0 auto"
  textAlign="center"
>


      {/* Titolo con Text */}
      <Text fontSize="xl" fontWeight="bold" mt="1rem">
        Efficienza e semplicità per il tuo studio
      </Text>

      {/* Paragrafo */}
      <Text fontSize="lg">
        L'app che ti aiuta a gestire i pazienti e <br/>
        l’amministrazione in modo smart, con pochi click.
      </Text>
      </Box>

      {/* Bottone sotto il testo del genitore */}
      <Box mt="2.5rem" pb={{ base: "0rem", md: "1.5rem", lg: "1.5rem" }}>
        <Button
          bg="primaryPediatrician.600"
          color="white"
          borderRadius="full"
          p="1.7rem 4rem"
          fontSize="xl"
          _hover={{ bg: "primaryPediatrician.700" }}
        >
          <Link to={'pediatrician'}> Scopri di più</Link>
        </Button>
      </Box>
    </Box>
    

  </Box>
  
</Box>








<Box 
  display="flex" 
  bg="tertiary.100" 
  pt={{ base: 10, md: 20 }}
  pb={20}
  px={{ base: 4, md: 20 }}
  borderRadius="md" 
  alignItems="center" 
  flexDirection={{ base: "column", md: "column", lg: "row" }}
  textAlign={{ base: "center", md: "left" }}
>
  {/* Per schermi piccoli e medi: Testo sopra, immagine al centro, testo sotto */}
  {/** Schermi piccoli e medi **/}
  <Box display={{ base: "block", lg: "none" }}>
    <Heading 
      size="5xl" 
      fontWeight="bold" 
      color="primaryPatient.600" 
      mb={5} 
      _hover={{ 
        transform: "scale(1.03)", 
        filter: "brightness(1.2)" 
      }}
    >
      <a href="https://ai-health.it" style={{ textDecoration: 'none' }}>
        Chi siamo
      </a> 
    </Heading>
  </Box>
  
  {/* Immagine al centro per mobile, a sinistra per desktop */}
  <Image 
    src="/images/team.jpg" 
    alt="Team Kiddocare" 
    width="600px" 
    height="auto" 
    borderRadius="md" 
    mt={{ base: 5, md: 4, lg: 0 }}
    mb={{ base: 4, md: 4, lg: 0 }}
    order={{ base: 1, md: 1, lg: 0 }} /* Mantiene immagine al centro per mobile */
  />
  
  {/* Testo sotto per mobile, a destra per desktop */}
  <Box ml={{ md: 8 }} textAlign={{ base: "center", md: "center" , lg:"left"}} order={{ base: 2, md: 2, lg: 1 }}>
    <Box display={{ base: "none", lg: "block" }}>
      <Heading 
        size="5xl" 
        fontWeight="bold"
        color="primaryPatient.600" 
        mb={5}  
        _hover={{ 
          transform: "scale(1.03)", 
          filter: "brightness(1.2)" 
        }}
      >
        <a href="https://ai-health.it" style={{ textDecoration: 'none' }}>
          Chi siamo 
        </a> 
      </Heading>
    </Box>
    <Box 
      flex="1"
      minWidth="150px"
      maxWidth={{ base: "300px", md: "650px" }} // ✅ CORRETTO
      margin="0 auto"
      textAlign="left"
    >
    <Text>
      
      Il team di Kiddocare è composto da giovani professionisti dinamici con competenze multidisciplinari in tecnologia, medicina e design.  
      Uniti dalla passione per l'innovazione, lavorano per trasformare il settore sanitario grazie alla loro esperienza e visione.
    </Text>
    </Box>
  </Box>

</Box>

      {/* 
<Box bg="primaryPatient.600">
<Box p={30}  width={{ base: "90%", md: "50%" }} mx="auto" bg="primaryPatient.600"> 
  <Heading  mb={6} size="4xl" color="white" textAlign="center"> Contattaci</Heading>
  
  <form action="mailto:support@kiddocare.it" method="POST" onSubmit={(e) => {
    const form = e.target;

    const nome = (form as HTMLFormElement).nome.value.trim();
    const cognome = (form as HTMLFormElement).nome.value.trim();
    const email = (form as HTMLFormElement).nome.value.trim();
    const provincia = (form as HTMLFormElement).nome.value.trim();
    if (!nome || !cognome || !email || !provincia) {
      e.preventDefault();
      alert("Tutti i campi obbligatori devono essere compilati.");
    }
  }}>
    <VStack  align="center" width="100%"> 
      <Flex gap={4} width="100%"> 
        <Input placeholder=" Nome" name="nome" required width="50%" bg="white" /> 
        <Input placeholder=" Cognome" name="cognome" required width="50%" bg="white" /> 
      </Flex>
      <Input type="email" placeholder=" Email" name="email" required width="100%" bg="white" /> 
      <Input placeholder=" Provincia" name="provincia" required width="100%" bg="white" />
      <Textarea placeholder=" Note aggiuntive" name="note" width="100%" height="150px" bg="white" />
      <Button type="submit" colorScheme="whiteAlpha" bg="white" color="black" width="60%" _hover={{ bg: "gray.300" }}>Invia</Button>
    </VStack>
  </form>
</Box>
</Box>


      <Flex
  direction={{ base: "column", md: "row" }}
  align="center"
  justify="space-between"
  px={ {base:"3rem", md: "3rem" , lg: "3rem"}}
  pt= { {base:"3rem", md: "3rem" , lg: "3rem"}}
  bg="primaryPatient.50"
>
  
  <Box flex="1" mr={{ md: "1rem" }} textAlign={{ base: "center", md: "left" }}>
    <Heading size="lg" fontWeight="bold" color="primaryPatient.600" mb="1.5rem">
    L'abbonamento ti dà accesso alla nostra app, dove puoi:
    </Heading>
   
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="secondaryPatient.700">
        ✔
      </Text>
      <Text fontSize="md">
        Consulto rapido con il pediatra: chat, telefonata, videochiamata
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
        Ricette, prescrizioni e certificati richiesti e scaricati direttamente.
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
       Prenotazione visite, convenzionate o private, da remoto o in studio
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
        Accesso allo storico di tuo figlio
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
       Tasto SOS, Emergenze
      </Text>
    </Flex>
  </Box>

  
  <Image
    src='/images/drawing/mano_home.png'
    alt="App Screenshot"
    maxW= { {base:"130%", md: "60%" , lg: "60%"}}
    h="auto"
    
  />
</Flex> 

      


<Box bg="white" py={20} textAlign="center">
  <Container maxW="container.lg">

    
     
    <Heading fontSize="2xl" fontWeight="bold" color="primaryPediatrician.700" textAlign="center" mb={4}>
      Scrivici per entrare a far parte della community di pediatri Kiddocare
    </Heading>

 
  <Flex justify="center" gap={6} pt={6}>
      
    
      <Link to="mailto:info@kiddocare.it">
        <Box 
          bg="yellow.400" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "yellow.500" }}
        >
          <Image src="/images/email.png" alt="Email" boxSize="60px" />
        </Box>
      </Link>

    
      <Link to="https://www.instagram.com/kiddocare.it/" >
        <Box 
          bg="teal.500" 
          w="80px" 
          h="80px" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          borderRadius="full"
          boxShadow="md"
          _hover={{ bg: "teal.600" }}
        >
          <Image src="/images/whatsapp.png" alt="WhatsApp" boxSize="60px" />
        </Box>
      </Link>

    </Flex>

    


   
  </Container>
</Box>*/}
      
    </Box>
  );
};

export default Home;
