import { useBreakpointValue } from "@chakra-ui/react";
import LinkPageMobile from "../payments/linkpagemobile";
import { PricingTableStripe } from "../../components/stripe/pricing-table.stripe";
import { Box, Container, Flex, Heading, Image, Text, Link, Em, Button } from "@chakra-ui/react";
import { Helpbar } from "../../layouts/helpbar";

const LinkPage = () => {
  
  // Se lo schermo è md o più piccolo, usa la versione mobile
  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false });

  if (isSmallScreen) {
    return <LinkPageMobile />; // Mostra il file mobile
  }

  return (
    <Box bg="white" py={6} position="relative" >
      <Container maxW="container.xl">
        <Flex direction="row" align="center" justify="right" gap={4} position="relative">
          {/* Box con testo e pulsante */}
          <Box textAlign="left" mt={-20} position="relative" zIndex="2">
            <Heading fontSize="7xl" fontWeight="bold" color="black" mb={8}>
              Io sono
            </Heading>
            <Text mb={4} fontSize="md" color="gray.600" maxW="300px">
              Kiddocare è l’app che ti aiuta a gestire online la salute dei tuoi figli, rimanendo sempre in contatto con il pediatra.
            </Text>
            <Button bg="primaryPatient.500" color="white" fontSize="lg" px={6} py={3} borderRadius="full" mb={6} onClick={() => document.getElementById("abbonamento-section")?.scrollIntoView({ behavior: "smooth" })}
            >
            Abbonati e Scarica l'APP
            </Button>
            </Box>

          {/* Immagine principale */}
          <Box position="relative">
            <Image src="/images/singup.png" alt="App Kiddocare" objectFit="contain" maxW="300px" position="relative" zIndex="2" />
            <Image
              src="/images/rectverde.png"
              alt="Sfondo"
              objectFit="cover"
              position="absolute"
              top="-50%"
              right="-180%"
              maxW="10000px"
              opacity={0.6}
              zIndex="1"
            />
          </Box>

          {/* Box con testo e immagine lampadina */}
          <Box textAlign="left" ml={{ md: -10, lg: 5 }} mt={{ md: 0, lg: 14 }} position="relative" zIndex="2">
            <Heading fontSize="7xl" fontWeight="bold" color="black" mb={8}>
              Kiddocare
            </Heading>
            <Image src="/images/lampadina.png" alt="Decorazione" objectFit="contain" maxW="500px" />
          </Box>
        </Flex>
      </Container>


      <Box bg="white" pt={20} pb={1} textAlign="center">
  <Container>
    {/* Titolo e descrizione */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPatient.700" textAlign="center" p={4} borderRadius="md" mb={8}>
      Ti mostro come posso aiutarti ogni giorno
    </Heading>

    <Text fontSize="2xl" color="gray.700" fontWeight="bold" mb={1}>
      Questa è la mia pagina principale
    </Text>
    <Text fontSize="lg" color="gray.600" mb={6}>
      Da qui potrai accedere a tutte le mie funzionalità
    </Text>

    {/* Layout 3 colonne con immagine centrale */}
    <Flex direction={{ base: "column", md: "row" }} align="center" justify="center" gap={8}>
      
      {/* Colonna sinistra */}
      <Box textAlign="right" maxW="400px" flex="1">
        <Flex direction="row-reverse" align="center" gap={3} mb={4}>
          <Image src="/images/icon3.png" alt="Salute" boxSize="40px" />
          <Text fontSize="md" color="gray.700">
            <strong>Prendersi cura</strong> <br /> della salute dei tuoi figli
          </Text>
        </Flex>

        <Flex direction="row-reverse" align="center" gap={3} mb={4}>
          <Image src="/images/icon2.png" alt="Pediatra" boxSize="40px" />
          <Text fontSize="md" color="gray.700">
            <strong>Consultare</strong> il Pediatra
          </Text>
        </Flex>
      </Box>

      {/* Immagine centrale */}
      <Box flex="1" display="flex" justifyContent="center">
        <Image src="/images/home2.png" alt="App Screen" maxW="500px" />
      </Box>

      {/* Colonna destra */}
      <Box textAlign="left" maxW="400px" flex="1">
        <Flex direction="row" align="center" gap={3} mb={4}>
          <Image src="/images/icon1.png" alt="Prenotazioni" boxSize="40px" />
          <Text fontSize="md" color="gray.700">
            Prenotare <strong>Visite</strong> in studio e <br /> <strong>Videochiamate</strong>
          </Text>
        </Flex>

        <Flex direction="row" align="center" gap={3}>
          <Image src="/images/icon.png" alt="Certificati" boxSize="45px" />
          <Text fontSize="md" color="gray.700">
            Richiedere <strong>certificati</strong> e <strong>prescrizioni</strong>
                </Text>
                </Flex>
            </Box>
            
            </Flex>
        </Container>
        </Box>


   {/* Terza sezione */}

    <Box bg="white" >
    <Container maxW="container.lg">
        {/* Titolo */}
        <Heading fontSize="4xl" fontWeight="bold" color="primaryPatient.700" textAlign="center" mb={8}>
        Conversazioni rapide con il pediatra
        </Heading>

    {/* Contenuto con immagine a sinistra e testo a destra */}
    <Flex align="center" justify="space-between" gap={10} pt={10} mb={20}>

      {/* Box Immagine */}
      <Box position="relative" flex="1" display="flex" justifyContent="center">
        {/* Immagine in sottoimpressione */}
        <Image
          src="/images/rectgiallo.png"
          alt="Sfondo"
          position="absolute"
          top="100%"
          left="100%"
          transform="translate(-80%, -60%)"
          maxW="900px"
          opacity={0.6}
          zIndex="1"
        />
        {/* Immagine principale */}
        <Image
          src="/images/chat1.png"
          alt="Consulto"
          maxW="550px"
          zIndex="1"
        />
      </Box>

      {/* Box Testo con rettangoli colorati */}
        <Box flex="1" textAlign="left" maxW="400px">
        <Flex align="center" gap={3} mb={4}>
            {/* Rettangolo Giallo */}
            <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
            
            {/* Testo */}
            <Text fontSize="lg" color="gray.700">
            <strong>Possibilità di comunicare</strong> tramite chat dedicata, telefonata e videochiamate.
            </Text>
        </Flex>

        <Flex align="center" gap={3}>
            {/* Rettangolo Azzurro */}
            <Box bg="teal.500" width="5px" height="40px" borderRadius="md"></Box>
            
            {/* Testo */}
            <Text fontSize="lg" color="gray.700">
            <strong>Meno spostamenti</strong> in ambulatorio.
            </Text>
        </Flex>
        </Box>


    </Flex>
  </Container>
</Box>


<Box bg="white" pt={20} pb={20} textAlign="center">
  <Container maxW="container.lg">
    {/* Titolo */}
    <Heading fontSize="3xl" fontWeight="bold" color="teal.700" mb={4}>
      Richieste automatiche rapide
    </Heading>

    {/* Testo */}
    <Text fontSize="lg" color="gray.700" maxW="800px" mx="auto" mb={8}>
      Ricette, certificati a <strong>portata di click</strong>.  <br/>
      Prescrizioni disponibili a breve.  <br/>
      Ricevi i documenti direttamente in app.
    </Text>

    {/* Immagini */}
    <Flex justify="center" flexWrap="wrap" gap={4}>
      <Image src="/images/pipeline.png" alt="Richiedi certificati" maxW="900px" />
      
    </Flex>
    </Container>
    </Box>
    
    <Box bg="white" pt={10}>
  <Container maxW="container.lg">


    {/* quinta sezione */}
    <Heading fontSize="4xl" fontWeight="bold" color="primaryPatient.700" textAlign="center" mb={20}>
      Prenotazione Visite
    </Heading>

    {/* Layout con lista allineata a destra e immagine a destra */}
    <Flex align="center" justify="space-between" >
      
      {/* Lista allineata a destra con rettangoli a sinistra */}
      <Box textAlign="right" maxW="700px" >
        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Giallo */}
          <Text fontSize="lg" color="gray.700">
            <strong>Prenota visite</strong>, convenzionate o private, direttamente in app.
          </Text>
          <Box bg="yellow.400" width="10px" height="70px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
          
        </Flex>

        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Verde */}
           <Text fontSize="lg" color="gray.700">
            Scegli tu <strong>quando vuoi</strong> in base alle disponibilità.
          </Text>
          <Box bg="teal.500" width="8px" height="50px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
         
        </Flex>

        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Giallo */}
          <Text fontSize="lg" color="gray.700">
            <strong>Visite in ambulatorio</strong>.
          </Text>
          <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
          
        </Flex>

        <Flex align="center" justify="flex-end" gap={3} mb={4}>
          {/* Rettangolo Verde */}
          <Text fontSize="lg" color="gray.700">
            <strong>Videochiamate</strong> direttamente in app, facili e sicure.
          </Text>
          <Box bg="teal.500" width="8px" height="50px" borderRadius="md"></Box>
          {/* Testo allineato a destra */}
          
        </Flex>

        <Flex align="center" justify="right" gap={3}>
          {/* Rettangolo Giallo */} 
          <Text fontSize="lg" color="gray.700">
            <strong>Disdici con un click</strong>.
          </Text>
          <Box bg="yellow.400" width="8px" height="50px" borderRadius="md"></Box>
          
        </Flex>
      </Box>


      <Box>
        {/* Immagine in sottoimpressione */}
        <Image
          src="/images/rectgiallo.png"
          alt="Sfondo"
          position="absolute"
          top="100%"
          left="100%"
          transform="translate(-90%, -80%)"
          maxW="1000px"
          opacity={0.6}
          zIndex="0"
        />
        {/* Immagine principale */}
        <Image
           src="/images/visite.png" 
           alt="Prenotazione Visite" 
           maxW="500px" 
           ml={-20} // Sposta a sinistra
           mr="auto" // Spinge verso sinistra
           position="relative" 
           left="-180px" // Ulteriore spostamento a sinistra
         
        />
      </Box>

    </Flex>
  </Container>
</Box>

{/* sesta sezione */}
<Box bg="white" py={20}>
  <Container maxW="container.lg">
    
    
    <Flex direction={{ base: "column", md: "row" }} align="flex-start" justify="center" gap={20}>
      
      {/* Colonna Sinistra: SOS ed Emergenze */}
      <Box textAlign="center" maxW="5000px">
        {/* Titolo */}
        <Heading fontSize="2xl" fontWeight="bold" color="primaryPatient.700" mb={10}>
          SOS ed Emergenze
        </Heading>
        
        {/* Immagine sotto il titolo */}
        

        {/* Testo */}
        <Text fontSize="lg" color="gray.700" >
          Se il pediatra lo abilita, potrai richiedere<br/> un consulto urgente per gestire le emergenze.*
           <br/>
          <Image 
          src="/images/sos.png" 
          alt="SOS ed Emergenze" 
          maxW="350px" 
          mb={5}
        /></Text>
      </Box>

      {/* Colonna Destra: Pagamenti sicuri e Privacy completa */}
      <Box textAlign="center" maxW="900px">
        {/* Titolo */}
        <Heading fontSize="2xl" fontWeight="bold" color="primaryPatient.700" mb={10}>
          Pagamenti sicuri e veloci <br /> e Privacy completa
        </Heading>
        
        {/* Icone */}
        <Flex justify="center" gap={5} mb={3}>
          <Image src="/images/gruppo.png" alt="Pagamenti Sicuri" maxW="300px" />
         
        </Flex>

        {/* Testo */}
        <Text fontSize="lg" color="gray.700">
          Garantiamo pagamenti sicuri direttamente in app <br/>e la protezione completa della tua privacy.
        </Text>
        <Text fontSize="lg" color="gray.700" mt={2}>
          Nessun altro, se non tu e il tuo pediatra,<br/> avrà accesso ai tuoi dati e alle tue foto.
        </Text>
      </Box>

    </Flex>
    <Text fontSize="md" color="gray.700" textAlign={"center"}>
   <Em>*  Il servizio SOS di Kiddocare facilita ai genitori la segnalazione di problemi urgenti dei figli per un consulto pediatrico.
    <br/>Non sostituisce i servizi di emergenza/urgenza del Servizio Sanitario Nazionale (es. 118, guardia medica).
       </Em></Text>

  </Container>
</Box>
<Box bg="white" py={20} textAlign="center">
  <Container maxW="container.lg">

    {/* Titolo principale */}
    <Heading fontSize="3xl" fontWeight="bold" color="black" mb={2}>
      Semplifica la gestione della salute di tuo figlio
    </Heading>
    <Heading fontSize="2xl" fontWeight="bold" color="black" mb={4}>
      Iscriviti ora su Kiddocare
    </Heading>

    {/* Sottotitolo */}
    <Text fontSize="lg" color="gray.600" mb={10}>
      Per sostenere lo sviluppo e il continuo aggiornamento dell'app è previsto un contributo accessibile.
    </Text>

    {/* Sezione "Come accedere" */}
    <Heading fontSize="xl" fontWeight="bold" color="black" mb={6}>
      Come accedere
    </Heading>

    <Flex direction={{ base: "column", md: "row" }} justify="center" gap={10} mb={8}>
      
      {/* Step 1 */}
      <Box maxW="250px">
      <Text fontSize="2xl" color="gray.700"> <strong>1</strong>
        </Text>
        <Image src="/images/Group1.png" alt="Invito" maxW="60px" mx="auto" mb={2} />
        <Text fontSize="lg" color="gray.700">
          Ricevi <strong>l'invito</strong> dal tuo pediatra
        </Text>
      </Box>

      {/* Step 2 */}
      <Box maxW="250px">
      <Text fontSize="2xl" color="gray.700"> <strong>2</strong>
      </Text>
        <Image src="/images/Group2.png" alt="Abbonamento" maxW="60px" mx="auto" mb={2} />
        <Text fontSize="lg" color="gray.700">
          Scegli <strong>l'abbonamento</strong>
        </Text>
      </Box>

      {/* Step 3 */}
      <Box maxW="250px">
      <Text fontSize="2xl" color="gray.700"> <strong>3</strong>
      </Text>
        <Image src="/images/Group3.png" alt="Credenziali" maxW="60px" mx="auto" mb={2} />
        <Text fontSize="lg" color="gray.700">
          Ricevi le <strong>credenziali</strong> di accesso via <strong>email</strong>
        </Text>
      </Box>

      {/* Step 4 */}
      <Box maxW="250px">
      <Text fontSize="2xl" color="gray.700"> <strong>4</strong>
      </Text>
        <Image src="/images/Group4.png" alt="Scarica App" maxW="60px" mx="auto" mb={2} />
        <Text fontSize="lg" color="gray.700">
          <strong>Scarica l'APP</strong>, accedi e inserisci il <strong>codice di invito</strong> del pediatra quando ti viene richiesto
        </Text>
      </Box>

    </Flex>

    {/* Pulsante centrale */}
    <Button bg="primaryPatient.500" color="white" fontSize="lg" px={6} py={3} borderRadius="full" mb={6} onClick={() => document.getElementById("abbonamento-section")?.scrollIntoView({ behavior: "smooth" })}
    >
      Abbonati e Scarica l'APP
    </Button>

    {/* Testo sotto il pulsante */}
    <Text fontSize="lg" color="primaryPatient.500" fontWeight="bold">
      Non hai ricevuto l’invito dal tuo pediatra?
    </Text>
    <Text fontSize="md" color="primaryPatient.500">
      Chiedi al tuo pediatra se fa parte della community Kiddocare o scrivici a <strong>info@kiddocare.it</strong>
    </Text>

  </Container>
</Box>




 <Box id="abbonamento-section" mt={6}>
    <PricingTableStripe />
    </Box>

    <Box mt={6}>
    <Helpbar />
    </Box>


    </Box>
  );
};

export default LinkPage;
