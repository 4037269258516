import { Container, Heading, Text, Em, Strong } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FaMartiniGlass } from 'react-icons/fa6';

export const TermsConditionsPediatrician: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>

      <Heading mt={'50'}> TERMINI E CONDIZIONI DI UTILIZZO </Heading>
      <Heading mt={'50'}> Kiddocare – Utente pediatra
      </Heading>
      <Heading mt={'2'} mb={'20'}> Premesse</Heading>

      <Text mt={4} textAlign="justify">
      Il presente documento riporta i <strong>termini contrattuali e le condizioni generali</strong> di utilizzo dell’applicazione <strong>Kiddocare</strong>, al fine del corretto utilizzo della infrastruttura informatico-telematica che consente al pediatra di interagire con il genitore (o altro esercente la potestà genitoriale o la rappresentanza legale) del paziente minore al fine della sua cura e della sua assistenza.      </Text>
      <Text mt={4} textAlign="justify">
      Tale applicazione si prefigge, pertanto, di migliorare la comunicazione tra il pediatra e il genitore e di rendere la prestazione sanitaria di cura e di assistenza del minore più efficace e tempestiva.      </Text>

      <Text mt={4} textAlign="justify">
      L’utilizzo della applicazione e della piattaforma <strong>Kiddocare</strong> è fornito al pediatra da <strong>AIHEALTH</strong> srl con sede in Milano, via Santa Sofia n. 24, P.IVA 13640270966, nella persona del suo legale rappresentante <Em>pro tempore</Em> Di Trana Michele, tel. 3403857266, indirizzo e-mail: amministrazione@ai-health.it, PEC: aihealth@pec.it.      </Text>
    
      <Text mt={4} textAlign="justify"> Le premesse costituiscono parte integrante del contenuto contrattuale.
      </Text>

      <Text mt={10} textAlign="center" fontSize={'xl'}>
      <strong>Termini contrattuali e condizioni d’utilizzo per utente <Em>pediatra</Em></strong>
      </Text>

      <Text mt={4} textAlign="center" fontSize={'xl'}> <strong>Termini contrattuali</strong></Text>



      <Text mt={4} textAlign="justify" pl={7}> <strong>1. Oggetto del contratto.</strong><br/>  
        Il presente contratto ha ad oggetto la fornitura a favore del pediatra della applicazione Kiddocare al fine della cura e della assistenza del paziente minore. Il dettaglio dei servizi e delle funzionalità che caratterizzano Kiddocare
        <Link to="../pediatrician/Allegato1" >  
          <strong> (Allegato 1)</strong> </Link>. 
      </Text>
      <Text mt={4} textAlign="justify" pl={7}><strong>2. Corrispettivi.</strong><br/> 
        L’utilizzo dell’applicazione da parte del pediatra è gratuito. Sulle sole prestazioni rese a pagamento con l’utilizzo della piattaforma (videochiamate, certificati ecc.), sarà applicata una commissione pari al 4% oltre iva come per legge, calcolato sul valore della prestazione. 
        <Link to="../pediatrician/Allegato2" >  
          <strong> (Allegato 2)</strong> </Link>.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}><strong>3. Registrazione.</strong><br/>  
        Per usufruire delle funzionalità dell’applicazione, il medico deve registrarsi fornendo, in modo corretto, esaustivo e veritiero, tutti i dati richiesti nel relativo <Em>form</Em> di registrazione ad esso specificamente riservato, in particolare il medico deve obbligatoriamente:</Text>

          <Text mt={4} textAlign="justify"pl={9}>
          -  accettare integralmente i termini contrattuali e le condizioni d’uso;
          </Text>
          <Text mt={4} textAlign="justify" pl={9}>
          -  previa lettura della relativa informativa 
          <Link to="../pediatrician/information-personal-data" >  
          <strong> (Allegato 3)</strong> </Link>, 
          rilasciare il consenso al trattamento dei dati personali necessari per l’esecuzione del contratto nonché – qualora interessato – a fini di marketing;
          </Text>
          <Text mt={4} textAlign="justify" pl={9}>
          -  rilasciare il consenso al trattamento dei propri dati per finalità di ricerca scientifica <Link to="../pediatrician/information-personal-data" >  
          <strong> (Allegato 3)</strong> </Link>. Si precisa che essi verranno anonimizzati prima dell’utilizzo a fini di ricerca.
          </Text>
          <Text mt={4} textAlign="justify" pl={9}>
          -  conferire a <strong>AIHEALTH srl</strong> la nomina a responsabile esterno dei dati dei propri pazienti trattati mediante l’applicazione <Link to="../pediatrician/nomination-data" >  
          <strong> (Allegato 4)</strong> </Link>

      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong> 4. Comunicazione di invito al genitore. </strong><br />
      Una volta compilato il <Em>form</Em>, accettati i presenti termini contrattuali e le condizioni d’uso e rilasciati i consensi necessari, il medico informerà con un messaggio i propri utenti dell’opportunità di ricevere in aggiunta alla tradizionale visita in presenza anche l’assistenza pediatrica attraverso consulenza e televisita a distanza con l’app Kiddocare, fermi ed impregiudicati gli obblighi e i vincoli di natura deontologica che il medico con la sottoscrizione delle presenti condizioni d’uso sarà tenuto a rispettare con il medesimo decoro e vigore e con la medesima probità richiesta dalla natura della sua professione.

      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>5. Autonomia e indipendenza del pediatra.  </strong><br/>Nel rapporto con il genitore del paziente minore, il medico agisce in piena autonomia e indipendenza, pertanto il presente accordo non fa sorgere tra il pediatra e <strong>AIHEALTH Srl</strong> alcun rapporto di collaborazione, agenzia, associazione, intermediazione o lavoro subordinato.

      </Text>

      <Text mt={4} textAlign="justify" pl={7}>
      <strong>6. Trattamento dei dati. Durata del trattamento.</strong><br/> I dati personali del pediatra saranno trattati secondo quanto riportato nella informativa privacy <Link to="../pediatrician/information-personal-data" >  
          <strong> (Allegato 3)</strong> </Link> . Tutti i dati che riguardano l’utente pediatra e che sono stati conferiti ai fini della esecuzione del contratto saranno trattati per tutta la durata del contratto stesso e per il periodo richiesto dall’espletamento degli obblighi di legge. I dati conferiti a fini di <Em>marketing</Em> saranno trattati fino a revoca espressa da parte del pediatra stesso.

      </Text>

      <Text mt={4} textAlign="justify" pl={7}>
      <strong>7. Utilizzo dei dati a fini di ricerca scientifica.</strong><br/> Previo consenso del pediatra, le informazioni acquisite nel corso dell’utilizzo della piattaforma potranno essere trattate a fini di ricerca scientifica e di addestramento dei sistemi di intelligenza artificiale <u>ma solo in modalità anonima</u>.</Text>

      <Text mt={4} textAlign="justify" pl={7}>
      <strong>8. Nomina a responsabile esterno a favore di AIHEALTH srl.</strong><br/>Il pediatra nomina espressamente <strong>AIHEALTH Srl</strong> responsabile esterno dei dati personali e sanitari del minore. L’atto di nomina <Link to="../pediatrician/nomination-data" >  
          <strong> (Allegato 4)</strong> </Link>, valido previa espressione del consenso.
      </Text>

      <Text mt={4} textAlign="justify" pl={7}>
      <strong>9. Regole e limiti di utilizzo. </strong><br/> L’utente riconosce che  <strong>AIHEALTH Srl </strong> potrà stabilire regole e limiti generali all’utilizzo dell’applicazione.
      <br/> <strong>AIHEALTH Srl </strong>si adopererà per assicurare che l’applicazione sia disponibile ininterrottamente 24 ore al giorno, e comunque nei normali orari di studio del pediatra, tuttavia l’accesso all’applicazione potrà essere sospeso temporaneamente e senza preavviso in caso di guasto del sistema, manutenzione, riparazioni o per ragioni del tutto estranee alla volontà di <strong>AIHEALTH Srl</strong> o per eventi di forza maggiore, o per impedimenti del medico o di un qualunque altro terzo e <strong>AIHEALTH Srl</strong> non potrà in alcun modo essere ritenuta responsabile.
   </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>10. Utilizzo dell’Intelligenza artificiale. </strong><br/> Previo consenso dell’utente, verranno impiegati sistemi di Intelligenza Artificiale, sia al fine di supportare la prestazione sanitaria, vuoi sotto il profilo diagnostico che terapeutico, sia per finalità organizzative, nonché - previa anonimizzazione degli stessi - a fini di ricerca scientifica e statistica. Per sistema di Intelligenza Artificiale (IA) si intende un sistema automatizzato progettato per funzionare con livelli di autonomia variabili e che deduce dalle informazioni che riceve come generare previsioni, contenuti, raccomandazioni o decisioni. L’uso di informazioni e il trattamento dei dati personali per finalità di sviluppo di sistemi di IA prevede che tali informazioni e dati siano utilizzati per l’addestramento del sistema e per la costruzione di algoritmi (modelli matematici che interpretano i dati). I sistemi di AI possono essere utilizzati, sotto la responsabilità del curante autorizzato, a fini di ausilio conoscitivo per agevolare la diagnosi (prevenzione, classificazione e stratificazione delle condizioni patologiche dei pazienti) e la comprensione di meccanismi fisiopatologici, per valutare quale trattamento di cura possa essere più appropriato e quale possa essere la risposta al trattamento e l’evoluzione della patologia. Inoltre, sotto il profilo della ricerca scientifica, la raccolta ed analisi di grandi quantità di dati sanitari permetterà lo sviluppo di modelli predittivi personalizzati, che aiuteranno ad individuare precocemente i pazienti a rischio di potenziali complicanze. Un’altra funzionalità riguarda la possibilità di migliorare l’organizzazione e l’efficienza delle prestazioni erogate dal pediatra, anche mediante l’interazione con l’agenda dei suoi appuntamenti e le giornate e le fasce orarie di reperibilità.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>11. Esonero da responsabilità per eventi dovuti a forza maggiore, caso fortuito, fatto di terzi. </strong><br/> <strong>AIHEALTH Srl</strong> non potrà essere ritenuta responsabile in caso di interruzioni del servizio causate da problemi tecnici alla stessa non imputabili o da forza maggiore. L’utente pediatra esonera pertanto sin d’ora <strong>AIHEALTH Srl</strong> da qualsivoglia responsabilità conseguente a mancato contatto con il genitore derivante da mancato funzionamento della infrastruttura per fatto del terzo, caso fortuito o forza maggiore.<br/>
      Il pediatra si impegna, inoltre, espressamente, e senza riserva alcuna, a tenere <strong>AIHEALTH Srl</strong> indenne e manlevata da qualsiasi danno, obbligo risarcitorio e/o sanzione in qualsiasi modo collegata alla violazione da parte sua delle regole sulla registrazione della applicazione ovvero conseguenti a non veridicità delle informazioni rese, o derivanti dalla violazione delle più idonee misure di conservazione delle credenziali di registrazione, ovvero da incapacità tecnologica nell’utilizzo dell’app.
  </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>12. Titolarità del diritto d’autore.<strong/><br/><strong></strong> Kiddocare</strong>  è tutelata dal diritto d’autore. <strong>AIHEALTH Srl </strong> dichiara di essere titolare e/o licenziataria di tutti i diritti di proprietà intellettuale relativi e/o afferenti all’applicazione. Pertanto tutti i marchi, figurativi o nominativi e tutti gli altri segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali, illustrazioni, immagini, loghi, contenuti relativi all’applicazione sono e rimangono di titolarità di <strong>AIHEALTH Srl </strong> o dei suoi licenziatari e sono protetti dalle leggi vigenti sui marchi e dai relativi trattati internazionali. Le condizioni non concedono al medico alcuna licenza d’uso relativa all’applicazione e/o a singoli contenuti e/o materiali ivi disponibili che il pediatra stesso non abbia immesso, se non diversamente disciplinato. Eventuali riproduzioni in qualunque forma dei testi esplicativi e dei contenuti dell’applicazione, qualora non autorizzate, saranno considerate violazioni del diritto proprietà intellettuale ed industriale del titolare.<br/>
      All’utente è fatto divieto copiare, modificare, utilizzare commercialmente il software che è alla base del funzionamento dell’applicazione, così come gli è fatto divieto di cercare di decifrare il codice sorgente e/o di tentare di apportarvi modifiche e/o creare lavori da esso derivati a scopo di lucro.
  </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong> 13. Modifiche ai termini contrattuali e alle condizioni d’uso. Accettazione. </strong><br/>  Eventuali modifiche ai presenti termini contrattuali saranno comunicate agli utenti mediante l’applicazione e dovranno essere accettate dal medico al primo accesso successivo alla modifica ai fini del mantenimento del rapporto contrattuale. In caso di mancata accettazione il contratto si intenderà risolto.<br/>
In ogni caso, <strong>AIHEALTH Srl </strong>  ai fini del miglioramento dell’applicazione <strong>Kiddocare</strong>, anche in base ai <Em>feedback</Em> ricevuti di volta in volta dagli utenti, si riserva fin d’ora il diritto di variare l’interfaccia grafica dell’applicazione, l’organizzazione dei suoi contenuti, nonché ogni altro aspetto che caratterizzi la funzionalità e la gestione dell’applicazione medesima, comunicando quanto prima al medico le relative istruzioni di utilizzo.
  </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>14. Durata del contratto.</strong><br/> Il presente contratto avrà durata pari a mesi 12 e sarà oggetto di rinnovo automatico salvo disdetta.</Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>15. Disdetta e rinnovo.</strong><br/> Qualora, entro il trentesimo giorno antecedente la scadenza del contratto una delle parti non comunichi all’altra tramite PEC la propria disdetta, il contratto si prorogherà per altri dodici mesi. Ulteriori rinnovi di pari durata si avranno in caso di mancata disdetta, sempre con 30 giorni di preavviso sulla scadenza del periodo di rinnovo.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>16. Diritto di recesso.</strong><br/> Prima della scadenza del termine naturale del contratto, il pediatra potrà comunicare all’altra la propria intenzione di recedere dal contratto mediante PEC senza necessità di giustificazione. 
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>17. Clausola risolutiva espressa.</strong><br/> Ai sensi dell’art. 1564 c.c., un inadempimento di notevole importanza da parte del pediatra tale da minare la fiducia tra i contraenti comporterà la risoluzione del contratto e la conseguente disattivazione dei servizi. In particolare, si considererà causa di risoluzione l’uso improprio dei servizi da parte del pediatra, qualora tale uso produca danni o sia diretto a violare norme di legge, diritti di <strong>AIHEALTH Srl</strong> o di terzi.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>18. Cancellazione dei dati del paziente. </strong><br/> In caso di disdetta, di recesso o di risoluzione contrattuale, il pediatra avrà a disposizione 60 giorni di tempo per scaricare e memorizzare in propri archivi i dati di cui è titolare. Scaduto il termine indicato, i dati potranno essere cancellati. Dopo 90 dalla disdetta, dal recesso o dalla risoluzione, l’account fornito al pediatra potrà essere definitivamente chiuso.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>19. Legge applicabile.  </strong><br/>  Il presente accordo è regolato dalla legge italiana e dalle norme dell’Unione Europea, nonché dalle convenzioni internazioni di pertinenza.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      <strong>20. Clausola arbitrale e foro competente.</strong><br/>n caso di controversie concernenti la validità, l’interpretazione, l’esecuzione e la risoluzione del presente accordo o ad esso collegate, l’utente si impegna alla ricerca di un componimento bonario, anche mediante il ricorso alla Camera arbitrale di Milano. Qualora la controversia non dovesse risolversi bonariamente e comunque entro 6 mesi dall’inizio della stessa, la controversia sarà di competenza del Tribunale di Milano.
      </Text>

    
      <Text mt={10} textAlign="center" fontSize={'xl'}>
      <strong>Condizioni generali di utilizzo</strong>
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
    

      21. Al fine di poter utilizzare l’applicazione e la piattaforma Kiddocare sono necessari all’utente l’accesso al web e gli opportuni devices ad esso collegati come computer, notebook, smartphone e tablet. I dati verranno trasmessi in modalità sicura attraverso il protocollo <Em>https</Em>.
   </Text>
     
      <Text mt={4} textAlign="justify" pl={7}>
      22. L’utente potrà accedere alla piattaforma tramite <Em>user name</Em> e <Em>password</Em> di minimo 8 caratteri alfanumerici con lettere maiuscole e minuscole e almeno un carattere speciale. Una volta ricevuta, l’utente dovrà provvedere alla personalizzazione della propria <Em>password</Em>. Poiché vengono trattati dati a contenuto sanitario, la <Em>password</Em> andrà rinnovata trimestralmente. Il mantenimento della segretezza dello <Em>user name</Em> e della <Em>password</Em> è di esclusiva responsabilità dell’utente.
      </Text>

      <Text mt={4} textAlign="justify" pl={7}>
      23. L’utente ha l’onere di custodire con la più elevata diligenza, commisurata alla natura sensibile dei contenuti gestiti tramite l’applicazione, le proprie credenziali di accesso che devono essere utilizzate esclusivamente dal medico stesso o senza possibilità alcuna di essere cedute a terzi. L’utente si impegna a tenerle segrete, ad assicurarsi che nessun terzo vi abbia accesso e a informare immediatamente <strong>AIHEALTH srl</strong>  nel caso in cui sospetti o venga a conoscenza di un uso indebito o di una non voluta divulgazione delle stesse. 
      </Text>
      
      <Text mt={4} textAlign="justify" pl={7}>
      24. In caso di smarrimento o di compromissione, verrà assegnata all’utente una nuova <Em>password</Em> mediante un’opportuna procedura con adeguato livello di sicurezza. L’utente si impegna a: comunicare tempestivamente qualsiasi utilizzo non autorizzato della propria <Em>password</Em> o del proprio <Em>account</Em>, nonché qualsiasi altra violazione delle regole di sicurezza di cui venga a conoscenza; si impegna, inoltre, a uscire dal proprio <Em>account </Em>al termine di ogni sessione. L’utente dovrà fornire le informazioni personali richieste dalla procedura di registrazione. Tali informazioni dovranno essere complete e corrispondenti al vero. Qualora 
      <strong> AIHEALTH srl </strong>dovesse ritenere le informazioni fornite false o gravemente incomplete, essa si riserva di negare la registrazione all’utente che le ha fornite o di impedirne i successivi accessi.
      </Text>
     
      <Text mt={4} textAlign="justify" pl={7}>
      25. L’utente è il solo responsabile dei contenuti caricati e trasmessi tramite l’applicazione <strong>Kiddocare. AIHEALTH srl</strong> non garantisce la veridicità, la qualità, l’aggiornamento di tali contenuti. L’utente si impegna <strong><u>a non utilizzare</u> Kiddocare</strong> per: a) caricare o in qualsiasi modo trasmettere o diffondere contenuti illeciti, che possano arrecare danno a soggetti terzi, contenuti diffamatori, osceni o lesivi della privacy; b) arrecare danno a minori di età; c) falsificare la propria identità; d) caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere un contenuto che non abbia il diritto di trasmettere o diffondere in forza di una previsione di legge, di contratto ovvero a causa di un rapporto fiduciario, per esempio informazioni riservate, informazioni confidenziali apprese in forza di un rapporto di lavoro o protette da un patto di riservatezza); e) caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere un contenuto che comporti la violazione di brevetti, marchi, segreti, diritti di autore o altri diritti di proprietà industriale e/o intellettuale di terzi soggetti; f) caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere pubblicità, materiale promozionale, “junk mail”, “spam” o qualsiasi altra forma di sollecitazione non autorizzata o non richiesta; g) caricare, inviare via e-mail o in altro modo trasmettere o diffondere virus informatici o altri codici, file o programmi creati per interrompere, distruggere o limitare il funzionamento di software, hardware o impianti di telecomunicazione; h) raccogliere o archiviare dati personali degli altri utenti.
      </Text>
     
      <Text mt={4} textAlign="justify" pl={7}>
      26. I dati saranno memorizzati in una banca dati ospitata da <Em>server</Em> che si trovano nel territorio europeo. Attraverso sistemi di <Em>backup</Em> giornaliero viene garantito che ogni dato memorizzato nel database non verrà perso o casualmente modificato.
      </Text>

      <Text mt={4} textAlign="justify" pl={7}>
      27. <strong>AIHEALTH srl</strong> si riserva il diritto di rifiutare o rimuovere qualsiasi contenuto immesso dagli utenti che costituisca violazione delle presenti condizioni generali di utilizzo o che risulti in altro modo reprensibile.
      </Text>

      <Text mt={4} textAlign="justify" pl={7}>
      28. L’utente si impegna a non riprodurre, duplicare, copiare, vendere, rivendere e comunque a non sfruttare a fini commerciali le funzionalità dell’applicazione.
      </Text>
     
      <Text mt={4} textAlign="justify" pl={7}> <u>Si precisa, inoltre, quanto segue:</u>
      </Text>
      
      <Text mt={4} textAlign="justify" pl={7}> 
      29. Per sua natura, <strong>Kiddocare</strong> non può essere utilizzata nelle situazioni di urgenza e di emergenza sanitaria. <strong> In questi casi il genitore del paziente è tenuto ad utilizzare i consueti canali di pronto soccorso e di emergenza sanitaria.
      </strong></Text>
      <Text mt={4} textAlign="justify" pl={7}>
      30. L’applicazione non può essere usata per la farmacovigilanza, intesa come segnalazione di eventi avversi gravi e significativi.
      </Text>
      
      <Text mt={10} textAlign="justify" pl={7}> <u>Costituiscono parte integrante del presente contratto gli Allegati come di seguito specificati:</u>
      </Text>

      <Text mt={4} textAlign="justify" pl={7}>
      <br/><Link to="../pediatrician/Allegato1ped" >  
      <u><strong>Allegato 1</strong>  Descrizione dettagliata delle funzionalità e dei servizi erogati da Kiddocare.</u></Link>
       <br/><Link to="../pediatrician/Allegato2ped" >  
       <u><strong>Allegato 2</strong> Descrizione dettagliata delle modalità e delle entità dei rimborsi.</u></Link>
      <br/><Link to="../pediatrician/information-personal-data" >  
      <u><strong>Allegato 3</strong>  Informativa privacy relativa al trattamento dei dati personali del pediatra.</u></Link>
      <br/><Link to="../pediatrician/nomination-data" >  
      <u><strong>Allegato 4</strong>  Nomina a responsabile esterno a favore di <strong>AIHEALTH srl.</strong></u></Link>

      </Text>

      <Text mt={10} textAlign="justify" pl={7}>
      <strong>Dichiaro di avere letto con attenzione e di approvare specificamente le pattuizioni contenute:</strong>
      </Text>
      <Text mt={4} textAlign="justify" pl={12}>
      <strong>- nei termini contrattuali: articoli da 1 a 20.</strong>

</Text>
      <Text mt={4} textAlign="justify" pl={12}>
      <strong>- nelle condizioni generali di utilizzo: articoli da 21 a 30.</strong>
      </Text>




      
    </Container>
  );
}
