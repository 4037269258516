import {  
  Box, 
  Container, 
  Flex, 
  Image, 
  Link, 
  Button, 
  Stack 
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Stato per il menu mobile

  return (
    <Box as="nav" bg="white" px={8} py={6} boxShadow="sm" borderBottom="1px solid" borderColor="gray.200">
      <Container maxW="container.lg">
        <Flex align="center" justify="space-between">
          
          {/* Logo */}
          <NavLink to="/">
            <Image 
              src="/logos/kiddocare-full.png" 
              alt="Download on the App Store" 
              height="40px"
            />
          </NavLink>
          
          {/* NAVBAR DESKTOP */}
          <Flex display={{ base: 'none', md: 'flex' }} align="center" gap={10}>
            
            
            <NavLink 
              to="https://ai-health.it/"
              style={{ fontSize: "md", fontWeight: "bold", color: "black", position: "relative", zIndex: 10 }}
            >
              Cosa Facciamo
            </NavLink>
            
            <NavLink 
              to="mailto:info@kiddocare.it" 
              style={{ fontSize: "md", fontWeight: "bold", color: "black", position: "relative", zIndex: 10 }}
            >
              Contatti
            </NavLink>

            {/* Pulsante "Sei un pediatra?" - DESKTOP */}
            <NavLink to="/pediatrician">
              <Button 
                variant="outline" 
                borderColor="primaryPediatrician.600" 
                color="primaryPediatrician.600" 
                borderRadius="full"
                _hover={{ bg: "blue.100" }}
                position="relative"
                zIndex={10}
              >
                Sei un pediatra?
              </Button>
            </NavLink>
          </Flex>

          {/* HAMBURGER MENU PER MOBILE */}
          <Box 
            display={{ base: 'flex', md: 'none' }} 
            flexDirection="column" 
            justifyContent="center" 
            gap="4px"
            onClick={() => setMenuOpen(!menuOpen)} // Toggle menu
            cursor="pointer"
            zIndex="1000"
          >
            <Box w="25px" h="3px" bg="primaryPatient.600" borderRadius="md"></Box>
            <Box w="25px" h="3px" bg="primaryPatient.600" borderRadius="md"></Box>
            <Box w="25px" h="3px" bg="primaryPatient.600" borderRadius="md"></Box>
          </Box>
        </Flex>
      </Container>
    
      {/* NAVBAR MOBILE */}
      {menuOpen && (
        <Box 
          pb={4} 
          pt={4}
          px={4} 
          bg="white" 
          display={{ base: "block", md: "none" }}
        >
          <Stack as="nav" gap={4}>
            <NavLink to="https://ai-health.it/" style={{ fontSize: "md", fontWeight: "bold", color: "black" }}>
              Cosa Facciamo
            </NavLink>
            
            <NavLink to="mailto:info@kiddocare.it" style={{ fontSize: "md", fontWeight: "bold", color: "black" }}>
              Contatti
            </NavLink>

            {/* Pulsante "Sei un pediatra?" - MOBILE */}
            <NavLink to="/pediatrician">
              <Button 
                variant="outline" 
                borderColor="primaryPediatrician.600" 
                color="primaryPediatrician.600" 
                borderRadius="full"
                _hover={{ bg: "blue.100" }}
              >
                Sei un pediatra?
              </Button>
            </NavLink>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
