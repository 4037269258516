
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PoliciesLayout } from '../layouts/policies.layout';
import { PublicLayout } from '../layouts/public.layout';
import { HomePage } from './home/home.page';
import { Home } from "./home/home2"
import { SubscriptionTest } from './payments/subscription.page';
import LinkPage from  './payments/linkpagedesktop';
import LinkPageMobile  from './payments/linkpagemobile';
import { SuccessPage } from './payments/success.page';
import { NotFoundPage } from './utilities/not-found.page';
import { PolicyPediatricianNominationPage } from './policies/pediatricians/nomination-personal-data-pediatrician.page';
import { PolicyPediatricianInformationPersonalDataPage } from './policies/pediatricians/information-personal-data-pediatrician.page';
import { TermsConditionsPediatrician } from './policies/pediatricians/terms-conditions-pediatrician.page';
import { PolicyParentToChildPersonalDataPage } from './policies/parents/information-personal-data-parentstochild.page';
import { PolicyParentPersonalDataPage } from './policies/parents/information-personal-data-parents.page';
import { PolicyPortalParentDataPage } from './policies/parents/portal-information-personal-data-parents.page';
import { PolicyParentPersonalDataPageBis } from './policies/parents/information-personal-data-parents-bis.page';
import { PolicyPrivacy } from './policies/pediatricians/recap-page';
import { PolicyPrivacyParent } from './policies/parents/recap-page';
import { Allegato1 } from './policies/parents/Allegato1';
import { Allegato2 } from './policies/parents/Allegato2';
import { Allegato1ped } from './policies/pediatricians/Allegato1';
import { Allegato2ped } from './policies/pediatricians/Allegato2';
import { TermsConditionsParent } from './policies/parents/terms-conditions-parent.page';
import PediatricianPage from './home/pediatrician.page';
import PediatricianMobile from './home/pediatrician.mobile';
import ParentPage from './home/parent.page';
import ParentPageMobile from './home/parent.page.mobile';


export const Main: React.FC = () => {

  return (    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route path="home2" element={<HomePage />} />
          <Route path="/" element={<Home/>} />
          <Route path="pediatricianmobile" element={<PediatricianMobile/>} />
          <Route path="pediatrician" element={<PediatricianPage/>} />
          <Route path="parentpage" element={<ParentPage/>} />
          <Route path="parentpagemobile" element={<ParentPageMobile/>} />
          <Route path="payment/subscription/success" element={<SuccessPage />} />
          <Route path="payment/subscription" element={<LinkPage />} />
          <Route path="payment/subscriptionMobile" element={<LinkPageMobile />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="payment/pediatrician" element={<LinkPage />} />
        </Route>
        <Route path="policies" element={<PoliciesLayout />}>
          <Route path="pediatrician/policy-privacy" element={<PolicyPrivacy />} />
          <Route path="parents/policy-privacy" element={<PolicyPrivacyParent />} />
          <Route path="pediatrician/information-personal-data" element={<PolicyPediatricianInformationPersonalDataPage />} />
          <Route path="pediatrician/nomination-data" element={<PolicyPediatricianNominationPage />} />
          <Route path="pediatrician/terms-conditions" element={<TermsConditionsPediatrician/>} />
          <Route path="parents/information-personal-data-child" element={<PolicyParentToChildPersonalDataPage />} />
          <Route path="parents/information-personal-data" element={<PolicyParentPersonalDataPage />} />
          <Route path="parents/information-personal-data-bis" element={< PolicyParentPersonalDataPageBis />} />
          <Route path="parents/Allegato1" element={< Allegato1 />} />
          <Route path="parents/Allegato2" element={< Allegato2 />} />
          <Route path="pediatrician/Allegato1ped" element={< Allegato1ped />} />
          <Route path="pediatrician/Allegato2ped" element={< Allegato2ped />} />
          <Route path="parents/terms-conditions" element={<TermsConditionsParent/>} />
          <Route path="parents/portal-information-personal-data-" element={<PolicyPortalParentDataPage 
          />} />
          
        </Route>
      </Routes>

    </BrowserRouter>
  );

}