import { Container, Heading, Text, Em } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';

export const Allegato2ped: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>
        <Heading mt={'50'}> Corrispettivi a carico dei pediatri per l’utilizzo della piattaforma KIDDOCARE 
                         
                
                         </Heading>
                         <Heading mt={'2'} mb={'20'}> proprietà AIHEALTH srl, via Santa Sofia 24 20122 Milano
                         </Heading>
                   
                         <Text mt={4} textAlign="justify" pl={7}>1.  L’utilizzo dell’applicazione da parte del pediatra è gratuito.  </Text>
                         <Text mt={4} textAlign="justify" pl={7}>2. Sulle sole prestazioni rese a pagamento con l’utilizzo della piattaforma KIDDOCARE (a titolo di esempio le videochiamate, l’emissione di certificati ecc.), sarà applicata una commissione pari al 4% oltre iva come per legge, calcolato sul valore della prestazione. Resta inclusa in questo importo anche il costo della commissione per il pagamento attraverso l’uso della carta di credito. </Text>
        
              </Container>
                       );
                     }