import { 
  Box,
  Container,
  Heading,
  Image,
  Text,
  Flex,
  Link
} from "@chakra-ui/react";
import React from "react";

export const HomePage: React.FC = () => {
  return (
    <Box bg="white" py={6} position="relative">
      <Container maxW="container.xl">
        {/* Sezione Hero */}
        <Flex direction={{ base: "column", md: "row" }} align="center" justify="center" gap={4} position="relative">
          
          {/* Box con testo e pulsante */}
          <Box textAlign="left" mt={{ base: -2, md: -20 }} position="relative" zIndex="2">
            <Heading fontSize={{ base: "5xl", md: "7xl" }} fontWeight="bold" color="black" mb={8}>
              Io sono
            </Heading>
            <Text mb={4} fontSize="md" color="gray.600" maxW="300px">
              Kiddocare è l’app che ti aiuta a gestire online la salute dei tuoi figli, rimanendo sempre in contatto con il pediatra.
            </Text>
            <Link href="../parents/Allegato2" style={{ textDecoration: "none" }}>
              <Box
                px={4}
                py={3}
                mb={3}
                borderWidth={2}
                borderColor="primaryPatient.600"
                bg="primaryPatient.600"
                color="white"
                rounded="full"
                textAlign="center"
                fontWeight="semibold"
                _hover={{ bg: "primaryPatient.400" }}
                _active={{ bg: "primaryPatient.400" }}
              >
                Abbonati e Scarica l'app
              </Box>
            </Link>
          </Box>

          {/* Immagine principale */}
          <Box position="relative">
            <Image
              src="/images/singup.png"
              alt="App Kiddocare"
              objectFit="contain"
              maxW={{ base: "250px", md: "300px" }}
              position="relative"
              zIndex="2"
            />
            
            {/* Immagine in sottoimpressione */}
            <Image
              src="/images/rectverde.png"
              alt="Sfondo"
              objectFit="cover"
              position="absolute"
              top="-50%"
              right="-180%"
              maxW="10000px"
              opacity={0.6} // Regola la trasparenza per effetto sottoimpressione
              zIndex="1"
            />
          </Box>

          {/* Box con testo e immagine lampadina */}
          <Box textAlign="left" ml={{ base: 0, md: -10 , lg:5}} mt={{ base: -4, md: 0, lg:14 }} position="relative" zIndex="2">
            <Heading fontSize={{ base: "5xl", md: "7xl" }} fontWeight="bold" color="black" mb={8}>
              Kiddocare
            </Heading>
            <Image
              src="/images/lampadina.png"
              alt="Decorazione"
              objectFit="contain"
              maxW={{ base: "300px", md: "400px", lg: "500px" }}
            />
          </Box>
        
          </Flex>
          </Container>
          
          {/* Seconda sezione */}
          <Box bg="white" py={20} textAlign="center">
            <Container maxW="container.xl">
              {/* Titolo e descrizione */}
              <Heading fontSize={{ base: "3xl", md: "4xl" }} fontWeight="bold" color="primaryParent.600" mb={2}>
                Ti mostro come posso aiutarti ogni giorno
              </Heading>
              <Text fontSize="lg" color="gray.600" mb={8}>
                Questa è la mia pagina principale. Da qui potrai accedere a tutte le mie funzionalità.
              </Text>

              {/* Layout 3 colonne con immagine centrale */}
              <Flex direction={{ base: "column", md: "row" }} align="center" justify="center" gap={8}>
                
                {/* Colonna sinistra */}
                <Box textAlign="left" maxW="300px">
                  <Flex align="center" gap={3} mb={4}>
                    <Image src="/icons/health-icon.png" alt="Salute" boxSize="24px" />
                    <Text fontSize="md" color="gray.700">Prendersi cura della salute dei tuoi figli</Text>
                  </Flex>
                  <Flex align="center" gap={3}>
                    <Image src="/icons/pediatrician-icon.png" alt="Pediatra" boxSize="24px" />
                    <Text fontSize="md" color="gray.700">Consultare il Pediatra</Text>
                  </Flex>
                </Box>

                {/* Immagine centrale */}
                <Image src="/images/app-screen.png" alt="App Screen" maxW="280px" />

                {/* Colonna destra */}
                <Box textAlign="left" maxW="300px">
                  <Flex align="center" gap={3} mb={4}>
                    <Image src="/icons/calendar-icon.png" alt="Prenotazioni" boxSize="24px" />
                    <Text fontSize="md" color="gray.700">Prenotare Visite in studio e Videochiamate</Text>
                  </Flex>
                  <Flex align="center" gap={3}>
                    <Image src="/icons/certificate-icon.png" alt="Certificati" boxSize="24px" />
                    <Text fontSize="md" color="gray.700">Richiedere certificati e prescrizioni</Text>
                  </Flex>
                </Box>

              </Flex>
            </Container>
          </Box>


                
              </Box>
    
    
  );
};

export default HomePage;
